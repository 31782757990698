import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import RenderIf from 'components/shared/RenderIf';
import StateMatcher from 'containers/StateMatcherContainer';
import Loading from 'components/shared/Loading';
import ReactGA from 'react-ga4';

const ConfirmCheck = ({ isConfirmed }) => {
  useEffect(() => {
    ReactGA.set({ page: '/voter/preferences-confirm' });
    ReactGA.send('pageview');
  }, []);
  return (
    <>
      <Loading />
      <StateMatcher
        matches={
          'preferences.waitForCriticalData.preferences.preferenceForm.init'
        }
      >
        <RenderIf condition={isConfirmed}>
          <Navigate to={'/dashboard'} replace />
        </RenderIf>
        <RenderIf condition={!isConfirmed}>
          <Navigate to={'/preferences'} replace />
        </RenderIf>
      </StateMatcher>
    </>
  );
};

ConfirmCheck.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
};

export default ConfirmCheck;
