import { connect } from 'react-redux';
import SupportNoticePreLogin from '../components/SupportNoticePreLogin';

const mapStateToProps = ({ branding }) => ({
  county: branding.name || branding.county || 'BallotTrax',
  phone: branding.contact_phone,
  email: branding.contact_email,
  url: branding.url || null,
});

export default connect(mapStateToProps)(SupportNoticePreLogin);
