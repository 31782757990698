import { connect } from 'react-redux';
import { toggleVoterProfile } from 'actionCreators';
import VoterProfileBtn from 'components/buttons/VoterProfileBtn';

const mapStateToProps = ({ user: { firstname, lastname } }) => ({
  user_name: firstname ? `${firstname} ${lastname}` : `${lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  toggleVoterProfile: () => {
    return dispatch(toggleVoterProfile());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VoterProfileBtn);
