import {
  Action,
  UserAction,
  UserError,
  UserIdleTimer,
} from '../interfaces/action';
import { User } from '../interfaces/user';
import initialUser from '../reducers/inititalUser';

const userReducer = (
  state: Readonly<User> = initialUser,
  action: Action,
): Readonly<User> => {
  switch (action.type) {
    case 'FETCH_USER':
    case 'UPDATE_USER':
      const { user } = action as UserAction;

      // Set a default value for properties that should not be null
      const userNonNullProperties = ['address', 'city'];
      userNonNullProperties.forEach((property) => {
        user[property] = user[property] !== null ? user[property] : '';
      });

      return { ...state, ...user };
    case 'UPDATE_USER_ERROR':
      const { validationMessages } = action as UserError;
      return { ...state, validationMessages };
    case 'LOGOUT':
      return initialUser;
    case 'SET_USER_IDLE_TIMER':
      const { idle_timer } = action as UserIdleTimer;
      return { ...state, idle_timer };
    default:
      return state;
  }
};

export default userReducer;
