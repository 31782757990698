import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import withCurrentLanguage from 'components/withCurrentLanguage';
import useFaq from '../../../apis/useFaq';
import Loading from '../../shared/Loading';

export const FAQ = ({ _t, currentLanguage }) => {
  const { isLoading, error, data } = useFaq(
    `/api/voter/faq?language=${currentLanguage}`,
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className={'error faq-modal-error'} role="region" aria-live="polite">
        {error.message}
      </div>
    );
  }

  if (data.content === undefined) {
    return null;
  }

  return (
    <div className={'content'}>
      <dl
        className="faq-dl"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    </div>
  );
};

export default withCurrentLanguage(withTranslate(FAQ));

FAQ.propTypes = {
  _t: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};
