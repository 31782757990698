import React from 'react';
import Icon from 'components/icons/Icon';
import PropTypes from 'prop-types';

const Loading = ({ className = '' }) => (
  <div
    className={`loading ${className}`}
    role="progressbar"
    aria-label="Loading"
  >
    <Icon type="loading" className="main-loading-icon" />
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
