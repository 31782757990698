import React from 'react';
import PropTypes from 'prop-types';

export default class Collapsable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: !!props.open,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { children } = this.props;

    return children({
      isOpen: this.state.isOpen,
      toggle: this.toggle,
    });
  }
}

Collapsable.propTypes = {
  children: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
