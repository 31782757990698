import React from 'react';
import PropTypes from 'prop-types';
import menu from 'img/menu.png';
import ArrowDropdown from '../../img/ArrowDropdown';

const VoterProfileBtn = ({ toggleVoterProfile, user_name }) => (
  <button className="voter-profile-button" onClick={toggleVoterProfile}>
    <span>{user_name}</span>
    <ArrowDropdown title={'Dropdown icon'} className="nav-bar-dropdown-icon" />
    <img src={menu} alt={'Voter Profile'} className="voter-profile-btn-icon" />
  </button>
);

export default VoterProfileBtn;

VoterProfileBtn.propTypes = {
  toggleVoterProfile: PropTypes.func.isRequired,
  user_name: PropTypes.string.isRequired,
};
