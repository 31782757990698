import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ className = '' }) => (
  <span className={`spacer ${className}`.trim()} role="none" />
);

export default Spacer;

Spacer.propTypes = {
  className: PropTypes.string,
};
