import React from 'react';
import PropTypes from 'prop-types';

export const NoActiveBallots = ({ title, description, showTitle = true }) => (
  <section
    className="no-active-ballots no-ballots dashboard-card"
    data-testid={'no-active-ballots'}
  >
    {showTitle ? <span className="dashboard-card-header">{title}</span> : null}
    <div className="no-ballot-content">
      <div>
        <hr />
        <p
          className="detail"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  </section>
);

export default NoActiveBallots;

NoActiveBallots.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  showTitle: PropTypes.bool,
};
