import React, { Fragment } from 'react';
import CurrentBallotDetailsHeader from 'components/currentBallots/CurrentBallotDetailsHeader';
import BallotPath from 'components/BallotPath';
import Collapsable from 'components/collapsables/Collapsable';
import PropTypes from 'prop-types';

const CurrentBallotDetails = ({ statusHistory, _t }) => (
  <Collapsable className="current-ballot-details">
    {({ isOpen, toggle }) => (
      <Fragment>
        <CurrentBallotDetailsHeader isOpen={isOpen} toggle={toggle} />
        {isOpen && <BallotPath statusHistory={statusHistory} />}
      </Fragment>
    )}
  </Collapsable>
);

export default CurrentBallotDetails;

CurrentBallotDetails.propTypes = {
  statusHistory: PropTypes.array.isRequired,
  _t: PropTypes.func.isRequired,
};
