import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import errorIcon from 'img/optin_indicator_error.png';
import ReactGA from 'react-ga4';

const Unsubscribe = ({ unsubscribed, email, error }) => {
  useEffect(() => {
    const url = window.location.href;
    if (error) {
      ReactGA.event({
        category: 'voter-unsubscribe',
        action: 'voter unsubscribe error',
        label: `URL: ${url}`,
      });
    }
    if (unsubscribed) {
      ReactGA.event({
        category: 'voter-unsubscribe',
        action: 'voter unsubscribe success',
        label: `URL: ${url}`,
      });
    }
  }, [error, unsubscribed]);

  return (
    <div className="unsubscribe">
      {error && (
        <Fragment>
          <h1 className={'h1-unsubscribe-error'}>
            <span className={'unsubscribe-error'}>
              <img alt={''} className={'error-icon'} src={errorIcon} />
              Unsubscribe Error
            </span>
          </h1>
          <div>{error}</div>
        </Fragment>
      )}
      {unsubscribed && (
        <Fragment>
          <h1>Unsubscribe Successful</h1>
          <div>
            Your email {email} has been removed. You will no longer receive
            emails from us.
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Unsubscribe;

Unsubscribe.propTypes = {
  unsubscribed: PropTypes.bool.isRequired,
  email: PropTypes.string,
  error: PropTypes.string,
};
