import 'config/polyfills';
import React from 'react';
import 'normalize.css';
import './index.css';
import './stylesheets/themes/index.scss';
import App from './containers/AppContainer';
import { unregister } from './registerServiceWorker';
import configureStore from './store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactGA from 'react-ga4';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { fetchLdClient } from './apis/fetchLdClient';
import { createRoot } from 'react-dom/client';

(async () => {
  const clientID = await fetchLdClient()
    .then((res) => {
      return res.client_id;
    })
    .catch((err) => {
      console.log(err);
    });

  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientID,
    user: {
      anonymous: true,
      key: 'anonymous-pre-login-voter',
    },
  });

  const store = configureStore();
  const queryClient = new QueryClient();

  ReactGA.initialize('UA-55273806-1', {
    testMode: false,
  });

  createRoot(document.getElementById('root') as Element).render(
    <Provider store={store}>
      <LDProvider>
        {/* type mismatch fixed in react-query 4 */}
        {/* See https://github.com/TanStack/query/issues/3476*/}
        {/* @ts-ignore*/}
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
        </QueryClientProvider>
      </LDProvider>
    </Provider>,
  );

  // unregister any existing service worker on page load
  unregister();
})();
