import React from 'react';
import { Field } from 'formik';
import PreferencesTitleSection from '../icons/PreferencesTitleSection';
import OptSwitch from '../OptSwitch';
import withTranslate from '../withTranslate';
import ErrorMessageList from '../notifications/ErrorMessageList';
import { openModal, sendAnalyticsModalView } from '../../actionCreators';
import { connect } from 'react-redux';

export interface SmsFieldProps {
  values: {
    sms: string;
    smsOptIn: boolean;
  };
  setValues: (o: object) => void;
  _t: (str: string) => string[];
  errors: {
    sms?: string[];
  };
  touched: {
    sms: any;
  };
  clickHandler: (type: string) => void;
}

export const SmsField = ({
  values,
  setValues,
  _t,
  errors,
  touched,
  clickHandler,
}: SmsFieldProps) => {
  return (
    <section className="preference-section sms-section">
      <div className="preference-section-header">
        <PreferencesTitleSection type={'sms'} title={_t('Opt-In Text')} />
        <OptSwitch
          _t={_t}
          id={'Text message'}
          optedIn={values.smsOptIn}
          onClick={() => {
            setValues({
              ...values,
              smsOptIn: !values.smsOptIn,
            });
          }}
        />
      </div>

      <div className="input-section">
        <label htmlFor={'sms'} className="preference-label">
          {_t('Text Number (US Number Only)')}
        </label>
        <div>
          <Field
            className="preference-input"
            id={'sms'}
            name={'sms'}
            placeholder={_t('Example: (303) 333-3333')}
            onChange={(e: any) => {
              setValues({
                ...values,
                sms: e.target.value,
                smsOptIn: true,
              });
            }}
            aria-describedby={'sms-error-list'}
          />
          <button
            className="clear-field"
            type="button"
            onClick={() => setValues({ ...values, sms: '', smsOptIn: false })}
          >
            &times;
            <div className="screen-reader">
              {_t('Clear text message field contents')}
            </div>
          </button>
        </div>
      </div>
      <div aria-live="polite" aria-atomic="true" aria-relevant="all">
        {touched.sms && (
          <ErrorMessageList
            errors={errors.sms}
            field={'sms'}
            limit={3}
            id={'sms-error-list'}
          />
        )}
        {!(errors.sms && touched.sms) &&
          (values.smsOptIn ? (
            <div className={'field-message'} role="region" aria-live="polite">
              {_t(
                'By providing your number and selecting to opt-in to SMS text alerts, you are agreeing to ' +
                  'receive ballot status and election reminder alerts related to voter participation. Message ' +
                  'frequency varies. Message and data rates may apply. Reply "HELP" for help and "STOP" to cancel.',
              )}{' '}
              <button
                type={'button'}
                className={'sms-link-button'}
                onClick={() => clickHandler('privacy')}
                tabIndex={0}
              >
                {_t('Privacy Policy')}
              </button>
              <button
                type={'button'}
                className={'sms-link-button'}
                onClick={() => clickHandler('smsPolicy')}
                tabIndex={1}
              >
                {_t('SMS Terms')}
              </button>
            </div>
          ) : (
            <div className={'field-message'} role="region" aria-live="polite">
              {_t('You will NOT receive ballot updates via Text Message')}
            </div>
          ))}
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  clickHandler: (type: string) => {
    dispatch(sendAnalyticsModalView(type));
    return dispatch(openModal(type));
  },
});

export default withTranslate(connect(null, mapDispatchToProps)(SmsField));
