import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import StateMatcher from 'containers/StateMatcherContainer';
import AmbiguousForm from 'containers/AmbiguousFormContainer';
import Loading from 'components/shared/Loading';
import withTranslate from 'components/withTranslate';
import FieldNote from 'components/notifications/FieldNote';
import LoginFormValidationSchema from 'components/validators/LoginFormValidationSchema';
import ErrorMessageRenderer from 'components/notifications/ErrorMessageRenderer';
import ReCAPTCHA from 'react-google-recaptcha';
import TokenForm from 'containers/TokenFormContainer';
import AmbiguousCancelButton from '../buttons/AmbiguousCancelButton';

export const LoginForm = (props) => {
  const { _t, values, setFieldValue, revert } = props;
  const recaptchaRef = React.createRef();

  useEffect(() => {
    const loginFormState = props.machine.value.loginForm;
    if (
      loginFormState === 'error' ||
      loginFormState === 'ambiguousError' ||
      loginFormState === 'tokenError'
    ) {
      window.grecaptcha.reset();
    }
  }, [props.machine.value.loginForm]);

  return (
    <>
      <StateMatcher matches={'loginForm.submitting'}>
        <div className="login-loading">
          <Loading />
        </div>
      </StateMatcher>

      <Form className={`login-form`}>
        <StateMatcher matches={['loginForm.visible', 'loginForm.error']}>
          <section className={'form-inputs'}>
            <div className={'form-row'}>
              <section className="firstname-section">
                <label className="first-name-label" htmlFor={'firstname'}>
                  {_t('FIRST NAME')}
                </label>
                <Field
                  id={'firstname'}
                  className="first-name"
                  name={'firstname'}
                  placeholder={_t("Example: 'John'")}
                  aria-describedby={'firstname-error'}
                />
                <FieldNote
                  message={
                    'First name required exactly as it appears on your voter registration.'
                  }
                />
                <ErrorMessage
                  name={'firstname'}
                  render={(error) => (
                    <ErrorMessageRenderer
                      error={error}
                      field={'firstname'}
                      id={'firstname-error'}
                    />
                  )}
                />
              </section>

              <section className="lastname-section">
                <label htmlFor={'lastname'}>{_t('LAST NAME')}</label>
                <Field
                  id={'lastname'}
                  name={'lastname'}
                  placeholder={_t("Example: 'Adams'")}
                  aria-describedby={'lastname-error'}
                />
                <ErrorMessage
                  name={'lastname'}
                  render={(error) => (
                    <ErrorMessageRenderer
                      error={error}
                      field={'lastname'}
                      id={'lastname-error'}
                    />
                  )}
                />
              </section>
            </div>

            <div className={'form-row'}>
              <section className="birthdate-section">
                <label htmlFor={'birthdate'}>{_t('DATE OF BIRTH')}</label>
                <Field
                  id={'birthdate'}
                  name={'birthdate'}
                  placeholder={_t("Example: 'MM-DD-YYYY' '07-20-1969'")}
                  aria-describedby={'birthdate-error'}
                />
                <ErrorMessage
                  name={'birthdate'}
                  render={(error) => (
                    <ErrorMessageRenderer
                      error={error}
                      field={'birthdate'}
                      id={'birthdate-error'}
                    />
                  )}
                />
              </section>

              <section className="zipcode-section">
                <label htmlFor={'zipcode'}>{_t('RESIDENTIAL ZIP CODE')}</label>
                <Field
                  id={'zipcode'}
                  name={'zipcode'}
                  placeholder={_t("Example: '80238'")}
                  type={'text'}
                  aria-describedby={'zipcode-error'}
                />
                <ErrorMessage
                  name={'zipcode'}
                  render={(error) => (
                    <ErrorMessageRenderer
                      error={error}
                      field={'zipcode'}
                      id={'zipcode-error'}
                    />
                  )}
                />
              </section>
            </div>
          </section>
        </StateMatcher>

        <StateMatcher
          matches={[
            'loginForm.ambiguous',
            'loginForm.ambiguousSubmitting',
            'loginForm.ambiguousError',
          ]}
        >
          <AmbiguousForm fields={values} />
        </StateMatcher>

        <StateMatcher
          matches={[
            'loginForm.token',
            'loginForm.tokenSubmitting',
            'loginForm.tokenError',
          ]}
        >
          <TokenForm fields={values} />
        </StateMatcher>

        <div className={'form-row'}>
          <div className={'recaptcha-section'}>
            <div key={_t('_RECAPTCHA_LANGUAGE').join('')}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size={'normal'}
                sitekey={'6Ld8GdEZAAAAAHgLt09QbMlyFiU4epzFiChjBTY0'}
                onChange={(v) => {
                  setFieldValue('recaptcha', v || '');
                }}
                hl={_t('_RECAPTCHA_LANGUAGE').join('')}
              />
            </div>
            <ErrorMessage
              name={'recaptcha'}
              render={(error) => (
                <ErrorMessageRenderer error={error} field={'recaptcha'} />
              )}
            />
          </div>
        </div>

        <StateMatcher matches={['loginForm.visible', 'loginForm.error']}>
          <section className={'form-buttons'}>
            <button className="login-button primary-button" type="submit">
              {_t('Register / Log In')}
            </button>
          </section>
        </StateMatcher>

        <StateMatcher
          matches={[
            'loginForm.ambiguous',
            'loginForm.ambiguousError',
            'loginForm.token',
            'loginForm.tokenError',
          ]}
        >
          <section className="ambiguous-buttons-container">
            <button
              className="ambiguous-login-button primary-button"
              type="submit"
            >
              {_t('Register / Log In')}
            </button>
            <AmbiguousCancelButton onClick={revert} _t={_t} />
          </section>
        </StateMatcher>
      </Form>
    </>
  );
};

const FormikForm = () => {
  let propsAndMethods = {
    mapPropsToValues: () => {
      return {
        firstname: '',
        lastname: '',
        zipcode: '',
        birthdate: '',
        recaptcha: '',
        provided_voter_id: '',
        token: '',
      };
    },
    validationSchema: LoginFormValidationSchema,
    handleSubmit: (values, bag) => {
      const loginFormState = bag.props.machine.value.loginForm;
      let valuesToSubmit = {
        firstname: values.firstname,
        lastname: values.lastname,
        zipcode: values.zipcode,
        birthdate: values.birthdate.replace(/\\/g, '/'),
        recaptcha: values.recaptcha,
      };
      if (
        loginFormState === 'ambiguous' ||
        loginFormState === 'ambiguousSubmitting' ||
        loginFormState === 'ambiguousError'
      ) {
        valuesToSubmit = {
          ...valuesToSubmit,
          provided_voter_id: values.provided_voter_id,
        };
      }
      if (
        loginFormState === 'token' ||
        loginFormState === 'tokenSubmitting' ||
        loginFormState === 'tokenError'
      ) {
        valuesToSubmit = {
          ...valuesToSubmit,
          token: values.token,
        };
        if (values.provided_voter_id) {
          valuesToSubmit = {
            ...valuesToSubmit,
            provided_voter_id: values.provided_voter_id,
          };
        }
      }
      bag.props.handleSubmit(valuesToSubmit);
    },
  };
  return withFormik(propsAndMethods)(LoginForm);
};

export default withTranslate(FormikForm());

LoginForm.propTypes = {
  values: PropTypes.object.isRequired,
  _t: PropTypes.func.isRequired,
  machine: PropTypes.shape({ value: PropTypes.PropTypes.object.isRequired }),
  revert: PropTypes.func.isRequired,
};
