import React from 'react';
import PropTypes from 'prop-types';

export const BallotDetail = ({ text }) => (
  <div className="ballot-detail" dangerouslySetInnerHTML={{ __html: text }} />
);

export default BallotDetail;

BallotDetail.propTypes = {
  text: PropTypes.string.isRequired,
};
