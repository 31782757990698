import initialAlerts from '../reducers/initialAlerts';
import { Action, AlertAction } from '../interfaces/action';
import { AlertState } from '../interfaces/alert';

const alertsReducer = (state = initialAlerts, action: Action): AlertState => {
  switch (action.type) {
    case 'SHOW_GLOBAL_ALERT': {
      const { alertType, message } = action as AlertAction;

      return {
        ...state,
        [alertType]: [...(state[alertType] || []), ...[message]],
      };
    }

    case 'CLOSE_ALL':
      return initialAlerts;
    default:
      return state;
  }
};

export default alertsReducer;
