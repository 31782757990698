import { combineReducers } from 'redux';
import { createReducer } from 'util/redux/redux-xstate';
import { machine } from 'statechart';
import userReducer from 'reducers/userReducer';
import ballotReducer from 'reducers/ballotReducer';
import alertsReducer from 'reducers/alertsReducer';
import alertsReducerNew from 'reducers/alertsReducerNew';
import brandingReducer from 'reducers/brandingReducer';
import languageReducer from 'reducers/languageReducer';
import deliveryTypesReducer from 'reducers/deliveryTypesReducer';
import noBallotStatusReducer from 'reducers/noBallotStatusReducer';
import translationsReducer from 'reducers/translationsReducer';
import loginFormInlineErrorReducer from 'reducers/loginFormInlineErrorReducer';
import modalReducer from 'reducers/modalReducer';
import launchDarklyReducer from './launchDarklyReducer';
import analyticsReducer from './analyticsReducer';

const rootReducer = combineReducers({
  machine: createReducer(machine.initialState),
  user: userReducer,
  alerts: alertsReducer,
  alertsNew: alertsReducerNew,
  ballots: ballotReducer,
  branding: brandingReducer,
  language: languageReducer,
  deliveryTypes: deliveryTypesReducer,
  noBallotStatus: noBallotStatusReducer,
  translations: translationsReducer,
  loginFormInlineErrors: loginFormInlineErrorReducer,
  modal: modalReducer,
  launchDarkly: launchDarklyReducer,
  analytics: analyticsReducer,
});

export default rootReducer;
