import { QueryObserverResult, useQuery } from 'react-query';
import { ReleaseNoteApiResponse } from '../../interfaces/releaseNotes';
import replaceUrlDomainWithBrowserDomain from '../../util/replaceUrlDomainWithBrowserDomain';
import { standardHeaders } from '../standardHeaders';
import processJsonResponse from '../processJsonResponse';
import handleJsonErrors from '../handleJsonErrors';
import processHalLinks from '../processHalLinks';

export const useFetchReleaseNotes = (
  url: string,
): QueryObserverResult<ReleaseNoteApiResponse, Error> => {
  const releaseNoteUrl = replaceUrlDomainWithBrowserDomain(url);
  return useQuery(
    [releaseNoteUrl],
    () => {
      return fetch(releaseNoteUrl, {
        method: 'GET',
        headers: standardHeaders(),
      })
        .then(processJsonResponse)
        .then(handleJsonErrors)
        .then(processHalLinks);
    },
    {
      staleTime: 30 * 60 * 1000, // 30 minutes
      cacheTime: 30 * 60 * 1000,
    },
  );
};
