import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const NotFoundPage = ({ _t }) => {
  useEffect(() => {
    ReactGA.set({ page: '/voter/NotFoundPage' });
    ReactGA.send('pageview');
  }, []);
  return (
    <div className="not-found-page page-layout">
      <h1>Page Not Found</h1>
      <Link to={'/'}>
        <button className="primary-button" type="submit">
          Go Back
        </button>
      </Link>
    </div>
  );
};

export default withTranslate(NotFoundPage);

NotFoundPage.propTypes = {
  _t: PropTypes.func.isRequired,
};
