import { User } from '../../interfaces/user';
import PreferenceErrors from '../../interfaces/PreferenceErrors';

export function checkUserValidationMessages({
  validationMessages,
}: User): PreferenceErrors {
  const errors: PreferenceErrors = {};

  if (validationMessages) {
    Object.keys(validationMessages).forEach((field) => {
      errors[field] = [];
      Object.values(validationMessages[field]).forEach((error) => {
        errors[field].push(error);
      });
    });
  }

  return errors;
}
