//Colors from /stylesheets/partials/._base.scss
//Exported so that they can be used in typescript components

export const ballotBlue = '#194788';
export const evenTableRow = '#e8ecf3';
export const disabledAccessible = '#767676';
export const disabledAccessibleOddRow = '#6a6a6a';
export const disabledAccessiblePlaceholder = '#4d6b8c';
export const errorRed = '#d45659';
export const successColor = '#2d8653';
export const cancelColor = '#4B4B4B';
export const primaryColor = '#03314c';
export const iconRed = '#F54444';
export const iconGreen = '#33AD4B';

export const iconHoverColor = '#FFFFFF';
export const iconStandardColor = '#404040';

export const filterBackground = '#F1F4F8';
