import React from 'react';

const ModalLink = ({
  clickHandler,
  text,
}: {
  clickHandler: (event: any) => void;
  text: string;
}) => (
  <button className={'modal-link-button'} onClick={clickHandler} tabIndex={0}>
    {text}
  </button>
);

export default ModalLink;
