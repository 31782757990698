import React from 'react';
import withTranslate from 'components/withTranslate';
import PropTypes from 'prop-types';

export const ErrorMessageRenderer = ({ error, _t, field, id }) => (
  <div className={`error ${field}`} role="region" aria-live="polite" id={id}>
    {_t(error, { disableWarning: true })}
  </div>
);

ErrorMessageRenderer.propTypes = {
  error: PropTypes.string.isRequired,
  _t: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default withTranslate(ErrorMessageRenderer);
