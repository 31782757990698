import { Message, Alert } from '../../interfaces/alert';
import { ValidationMessages } from '../../interfaces/validationMessages';

const preferenceValidationErrorHandler = (validationMessages: {
  [field: string]: { [error: string]: string };
}): Alert[] => {
  const fields = Object.keys(validationMessages);
  const validationAlerts: Alert[] = [];

  fields.forEach((field) => {
    const fieldMessages = validationMessages[field];
    const messageKeys = Object.keys(fieldMessages);
    const fieldAlerts = messageKeys.map((key) => ({
      label: field,
      message: fieldMessages[key],
    }));
    validationAlerts.push(...fieldAlerts);
  });

  return validationAlerts;
};

export default preferenceValidationErrorHandler;

export const preferenceValidationErrorHandlerNew = (
  validationMessages: ValidationMessages,
): Message[] => {
  const messages: Message[] = [];

  Object.entries(validationMessages).forEach(([, errorObject]) => {
    // 'field' comes in and will be used in icon but is unused currently BAL-6749
    Object.values(errorObject).forEach((error) => {
      messages.push({
        text: error,
        important: false,
      });
    });
  });

  return messages;
};
