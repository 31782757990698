import React from 'react';
import PropTypes from 'prop-types';
import Spacer from 'components/shared/Spacer';
import BallotLogo from './BallotLogo';

const LogoCounty = ({ countyName, _t }) => {
  const countyNameOverride = _t('COUNTY_NAME_OVERRIDE', {
    disableWarning: true,
  });

  return (
    <div className="logo-county" data-testid="logo-county">
      <div className={'logo-container'}>
        <BallotLogo />
      </div>
      <Spacer className="logo-county-spacer" />
      {countyNameOverride[0] !== 'COUNTY_NAME_OVERRIDE' ? (
        <span
          className="logo-county-name"
          dangerouslySetInnerHTML={{ __html: countyNameOverride }}
        />
      ) : (
        <span className={'logo-county-name'}>{countyName}</span>
      )}
    </div>
  );
};

export default LogoCounty;

LogoCounty.propTypes = {
  countyName: PropTypes.string.isRequired,
  _t: PropTypes.func.isRequired,
};
