import { connect } from 'react-redux';
import HomePage from 'components/pages/HomePage';

const mapStateToProps = ({ machine, branding }) => ({
  isLoggedIn: machine.value.app === 'loggedIn',
  customBackgroundImage: branding.bg_url,
  logo: branding.logo_url,
  accountId: branding.account_id,
});

export default connect(mapStateToProps, null)(HomePage);
