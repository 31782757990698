import React, { useState } from 'react';
import { useFetchReleaseNotes } from '../../../apis/reactQueryHooks/useReleaseNote';
import Loading from '../../shared/Loading';
import withTranslate from '../../withTranslate';
import Pagination from '../../buttons/Pagination';
import replaceUrlDomainWithBrowserDomain from '../../../util/replaceUrlDomainWithBrowserDomain';

const releaseNoteUrl = replaceUrlDomainWithBrowserDomain(
  '/api/voter/release-note',
);

export const ReleaseNotes = () => {
  const [paginationUrl, setPaginationUrl] = useState(releaseNoteUrl);

  const { data, isLoading, error } = useFetchReleaseNotes(paginationUrl);
  const paginationLinks =
    data && data?.paginationLinks
      ? data.paginationLinks
      : { page: 1, pageCount: 1 };

  if (isLoading) {
    return (
      <div className={'release-notes-loading'}>
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className={''} role={'region'} aria-live={'polite'}>
        Apologies, we are having difficulties loading this page.
      </div>
    );
  }

  if (!data || data._embedded.release_note.length === 0) {
    return <p>No release notes available</p>;
  }

  return (
    <div className={'release-notes-container'}>
      <p className={'description'}>
        <span>BallotTrax release updates:</span>
        {/*The span below is hidden to screen readers. The screen reader says the number of list
        items when it gets to the list group.*/}
        <span aria-hidden={true} className={'pagination-description'}>
          {data._embedded.release_note.length} notes displayed on this page
        </span>
      </p>

      <ul className={'release-notes-list'}>
        {data._embedded.release_note.map((releaseNote) => {
          const dateObject = new Date(releaseNote.published_datetime);
          const publishedDate = new Intl.DateTimeFormat('en-US').format(
            dateObject,
          );
          const publishedDateScreenReader = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }).format(dateObject);
          return (
            <li
              key={releaseNote.release_note_id}
              className={'release-notes-li'}
            >
              <div className={'screen-reader'} id={'screen-reader-date'}>
                {publishedDateScreenReader}
              </div>
              <div
                aria-labelledby={'screen-reader-date'}
                className={'published-note'}
              >
                {publishedDate}
              </div>
              <div className={'note'}>{releaseNote.release_note}</div>
            </li>
          );
        })}
      </ul>

      <Pagination
        paginationLinks={paginationLinks}
        setPaginationUrl={setPaginationUrl}
        className={'release-notes-pagination'}
      />
    </div>
  );
};

export default withTranslate(ReleaseNotes);
