import React from 'react';
import PropTypes from 'prop-types';

const HaltedGraphic = ({ displayText, graphicType }) => (
  <div className={graphicType}>
    <span>{displayText}</span>
  </div>
);

export default HaltedGraphic;

HaltedGraphic.propTypes = {
  displayText: PropTypes.string.isRequired,
  graphicType: PropTypes.string.isRequired,
};
