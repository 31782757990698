import { connect } from 'react-redux';
import SupportNotice from 'components/SupportNotice';

const mapStateToProps = ({ branding }) => ({
  county: branding.name || branding.county || 'BallotTrax',
  phone: branding.contact_phone,
  email: branding.contact_email,
  url: branding.url || null,
});

export default connect(mapStateToProps)(SupportNotice);
