import {
  ballotStatusProgress,
  error,
  login,
  closeAll,
  brandingSuccess,
  success,
  updateUser,
  logout,
  fetchDeliveryTypes,
  fetchUser,
  noCurrentBallots,
  criticalDataReady,
  setAvailableLanguages,
  translationsSuccess,
  setCurrentLanguage,
  setLoginErrors,
  translationsLoading,
  setLaunchDarklyClientId,
  sendAnalyticsEvent,
  sendAnalyticsPageView,
  setUserIdleTimer,
  setLDFlags,
  ambiguous,
  token,
} from 'actionCreators';
import cookies from 'util/cookies/cookies';
import getLocaleFromLanguage from 'util/language/getLocaleFromLanguage';
import loginErrorMessage from 'util/error/loginErrorMessage';
import preferencesFormAlertBuilder from 'util/alerts/preferenceForm/preferencesFormAlertBuilder';
import { setUpdateUserError } from './actionCreators';
import sanitize from './util/sanitize/sanitize';
import formatPhoneNumber from './util/phone/formatPhoneNumber';
import getBaseUrl from './util/baseUrl';

let brandingVoiceCallNumber = '';

// NOTE: the second argument to fetch MUST include "credentials: 'same-origin'
// or else cookies won't get sent or received in Edge browser
// (if you don't need cookies for that request, you can omit it)
const actionMap = {
  submitLoginForm: (dispatch, state, { user }) => {
    dispatch(closeAll());
    return fetch(`${getBaseUrl()}/api/voter/session`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      body: JSON.stringify(user),
    })
      .then((res) => {
        const theJson = res.json();
        if (res.ok || theJson) {
          return theJson;
        }
        // If we get a bad response and it's not JSON then we have a new problem
        throw new Error('Something went wrong.');
      })
      .then((json) => {
        if (json.session_id) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'successful login',
            }),
          );
          return dispatch(login());
        }
        if (json['error-code'] === 8889) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'ambiguous login attempt',
            }),
          );
          dispatch(ambiguous());
          dispatch(loginErrorMessage(json.detail));
        } else if (json['error-code'] === 9999) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'Invalid ReCAPTCHA',
            }),
          );
          dispatch(error());
          dispatch(loginErrorMessage(json.detail));
        } else if (json['error-code'] === 8890) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'voter token required',
            }),
          );
          dispatch(token());
        } else {
          dispatch(error());
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'voter login error',
            }),
          );
          dispatch(setLoginErrors(json.validation_messages || {}));
          dispatch(loginErrorMessage(json.detail, json.err_id || null));
        }
      })
      .catch((e) => {
        dispatch(
          sendAnalyticsEvent({
            category: 'voter',
            action: 'unhandled voter login error',
          }),
        );
        dispatch(error());
        dispatch(loginErrorMessage(e.message));
      });
  },
  submitAmbiguousLoginForm: (dispatch, state, { user }) => {
    return fetch(`${getBaseUrl()}/api/voter/session`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.session_id) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'successful ambiguous voter login',
            }),
          );
          return dispatch(login());
        }
        if (json['error-code'] === 8890) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'voter token required',
            }),
          );
          dispatch(token());
          return;
        }
        dispatch(
          sendAnalyticsEvent({
            category: 'voter',
            action: 'failed ambiguous voter login',
          }),
        );
        dispatch(error());
        dispatch(loginErrorMessage(json.detail));
      });
  },
  submitTokenLoginForm: (dispatch, state, { user }) => {
    return fetch(`${getBaseUrl()}/api/voter/session`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.session_id) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'successful voter token login',
            }),
          );
          return dispatch(login());
        }
        dispatch(
          sendAnalyticsEvent({
            category: 'voter',
            action: 'failed voter token login',
          }),
        );
        dispatch(error());
        dispatch(loginErrorMessage(json.detail));
      });
  },
  ballotStatusProgress: (dispatch) => {
    const language = cookies.get('language') || 'english';
    return fetch(
      `${getBaseUrl()}/api/voter/ballot-status-progress/me?language=${language}`,
      {
        credentials: 'same-origin',
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('response not ok');
      })
      .then(({ ballots }) => {
        dispatch(ballotStatusProgress(ballots));
        dispatch(login());
        dispatch(
          sendAnalyticsEvent({
            category: 'voter-ballot',
            action: 'retrieve ballot status progress: success',
          }),
        );
        if (
          ballots.length === 0 ||
          ballots.every(
            (ballot) => ballot.election.election_is_active === false,
          )
        ) {
          return actionMap.noBallotStatus(dispatch, language);
        }
      })
      .catch(() => {
        dispatch(logout());
      });
  },
  noBallotStatus: (dispatch, language) => {
    return fetch(`${getBaseUrl()}/api/voter/no-ballot-status/${language}`, {
      credentials: 'same-origin',
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 404) {
            // Only get it in English if the language wasn't already english
            if (language !== 'english') {
              dispatch(
                sendAnalyticsEvent({
                  category: 'voter-ballot',
                  action: `failed to retrieve noballotstatus for ${language}`,
                }),
              );
              actionMap.setCurrentLanguage(dispatch, 'english');
            }
            return null;
          }
          throw new Error('error fetching no-ballot-status');
        }
        dispatch(
          sendAnalyticsEvent({
            category: 'voter-ballot',
            action: `retrieved noballotstatus for ${language}`,
          }),
        );
        return res.json();
      })
      .then((json) => {
        // If no json returned (e.g. a 404 on English translation), do nothing
        if (json) {
          dispatch(noCurrentBallots(json));
        }
      })
      .catch(() => {
        dispatch(logout());
      });
  },
  getDeliveryTypes: (dispatch) => {
    return fetch(`${getBaseUrl()}/api/voter/delivery-type`, {
      credentials: 'same-origin',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('delivery types response not ok');
      })
      .then(({ _embedded }) => {
        const deliveryTypes = _embedded.delivery_type;

        dispatch(login());
        dispatch(fetchDeliveryTypes(deliveryTypes));
      })
      .catch(() => {
        dispatch(logout());
      });
  },
  brandingCall: (dispatch) => {
    return fetch(`${getBaseUrl()}/api/voter/branding`, {
      credentials: 'same-origin',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        dispatch(
          sendAnalyticsEvent({
            category: 'voter-branding',
            action: `failed to retrieve voter branding`,
          }),
        );
        throw new Error(res);
      })
      .then((json) => {
        const [brandingData] = json._embedded.branding;
        if (
          brandingData.account_id === -1 &&
          brandingData.has_subdomain === true
        ) {
          window.location.href = 'https://wheresmyballot.com/';
        }

        dispatch(brandingSuccess(brandingData));
        dispatch(setAvailableLanguages(brandingData.languages));
        brandingVoiceCallNumber =
          brandingData.from_voice && formatPhoneNumber(brandingData.from_voice);

        // Update page name with branding data
        if (brandingData.name) {
          actionMap.setPageTitle(brandingData.name);
        }
        if (brandingData.is_voter_authenticated) {
          return dispatch(login());
        }
        return dispatch(logout());
      })
      .catch((err) => {
        console.error(err);
      });
  },
  clearSession: (dispatch) => {
    dispatch(logout());
    return fetch(`${getBaseUrl()}/api/voter/session`, {
      method: 'DELETE',
      credentials: 'same-origin',
    })
      .then((res) => {
        if (!res.ok) {
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: `voter logout failure`,
            }),
          );
          throw new Error('clearSession call failure');
        }
        dispatch(
          sendAnalyticsEvent({
            category: 'voter',
            action: `voter logout`,
          }),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  },
  submitPreferencesForm: (dispatch, state, { user, isConfirmed }) => {
    dispatch(closeAll());
    return fetch(`${getBaseUrl()}/api/voter/information/me`, {
      method: 'PATCH',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      credentials: 'same-origin',
      body: JSON.stringify(user),
    })
      .then((res) => {
        return Promise.all([res.json(), res]);
      })
      .then(([json, { status, ok }]) => {
        dispatch(
          preferencesFormAlertBuilder(user, json, {
            ok,
            status,
            isConfirmed,
            brandingVoiceCallNumber,
          }),
        );

        if (!ok) {
          if (status === 403) {
            dispatch(logout());
            throw new Error('Session expired');
          } else if (status === 422) {
            dispatch(setUpdateUserError(json.validation_messages));
          } else if (status >= 500) {
            throw new Error('Submit preference form response not ok');
          }
          dispatch(error());
        } else {
          dispatch(success());
          dispatch(updateUser(json));
          dispatch(
            sendAnalyticsEvent({
              category: 'voter',
              action: 'updated communication preferences',
            }),
          );
          actionMap.resetUserIdlingTracking();
        }
      })
      .catch(() => {
        dispatch(
          sendAnalyticsEvent({
            category: 'voter',
            action: 'failed to communication preferences',
          }),
        );
        dispatch(error());
      });
  },
  fetchVoterInformation: (dispatch) => {
    return fetch(`${getBaseUrl()}/api/voter/information`, {
      credentials: 'same-origin',
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Voter information response not ok');
        } else {
          return res.json();
        }
      })
      .then((json) => {
        const user = json._embedded.voter_information[0];
        dispatch(login());
        return dispatch(fetchUser(user));
      })
      .catch(() => {
        return dispatch(logout());
      });
  },
  reset: (dispatch) => {
    return actionMap.clearSession(dispatch).then(() => {
      actionMap.brandingCall(dispatch); // voter languages and branding data
      actionMap.getTranslations(dispatch);
    });
  },
  fetchCriticalData: (dispatch) => {
    return Promise.all([
      actionMap.brandingCall(dispatch), // voter languages and branding data
      actionMap.getDeliveryTypes(dispatch),
      actionMap.ballotStatusProgress(dispatch),
      actionMap.fetchVoterInformation(dispatch),
      actionMap.getTranslations(dispatch),
    ])
      .then(() => {
        dispatch(criticalDataReady());
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getTranslations: (dispatch) => {
    const currentLanguage = cookies.get('language') || 'english';
    const locale = getLocaleFromLanguage(currentLanguage);
    dispatch(translationsLoading());
    return fetch(
      `${getBaseUrl()}/api/voter/language-file?section=main&language=${locale}`,
      {
        credentials: 'same-origin',
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res);
      })
      .then((translations) => {
        dispatch(translationsSuccess(translations));
        if (translations.is_voter_authenticated) {
          return dispatch(login());
        }
        return dispatch(logout());
      })
      .catch((err) => {
        dispatch(
          sendAnalyticsEvent({
            category: 'voter',
            action: 'translations download failed',
          }),
        );
        console.error(err);
      });
  },
  setCurrentLanguage: (dispatch, language, isLoggedIn = null) => {
    dispatch(setCurrentLanguage(language));
    dispatch(
      sendAnalyticsEvent({
        category: 'voter',
        action: `changed language to ${language}`,
      }),
    );
    cookies.set('language', language);
    if (isLoggedIn === false) {
      return actionMap.getTranslations(dispatch);
    }
    return Promise.all([
      actionMap.getTranslations(dispatch),
      actionMap.ballotStatusProgress(dispatch),
    ]);
  },
  setCurrentLanguageToCookieValue: (dispatch) => {
    const currentLanguage = cookies.get('language') || 'english';
    dispatch(setCurrentLanguage(currentLanguage));
  },
  setLaunchDarklyClientId: (dispatch) => {
    fetch(`${getBaseUrl()}/api/ldclient.php`)
      .then((response) => {
        return response.json();
      })
      .then(({ client_id }) => {
        dispatch(setLaunchDarklyClientId(client_id));
      });
  },
  updateLaunchDarklyState: (dispatch) => {
    actionMap.setLaunchDarklyClientId(dispatch);
  },
  closeGlobalAlerts: (dispatch) => {
    dispatch(closeAll());
  },
  setPageTitle: (title) => {
    document.title = sanitize(title);
  },
  sendAnalyticsEvent: (dispatch, event) => {
    dispatch(sendAnalyticsEvent(event));
  },
  sendAnalyticsPageView: (dispatch, page) => {
    dispatch(sendAnalyticsPageView(page));
  },
  startUserIdlingTracking: (dispatch, state) => {
    const isUserIdle = () => {
      const maxIdleTimout = 1440000; //(1440000) 24 minutes;
      const now = Date.now();
      const userIdleTimestamp =
        window.localStorage.getItem('user_idle_timestamp') || now;
      const timeoutTimestamp = +userIdleTimestamp + maxIdleTimout;

      return Date.now() >= timeoutTimestamp;
    };

    // Start a single timer (interval). Log the user out if idle time is reached.
    if (!state.user?.idle_timer) {
      actionMap.resetUserIdlingTracking();
      const timer = setInterval(() => isUserIdle() && dispatch(logout()), 1000); // every second

      // Save timer
      dispatch(setUserIdleTimer(timer));
    }
  },
  resetUserIdlingTracking: () => {
    // Save user idle timestamp to localStorage, other windows will be able to monitor this value.
    window.localStorage.setItem(
      'user_idle_timestamp',
      new Date().getTime().toString(),
    );
  },
  stopUserIdlingTracking: (dispatch, state) => {
    state.user?.idle_timer && clearInterval(state.user.idle_timer);
    dispatch(setUserIdleTimer(null));
  },
  setLDFlags: (dispatch, flags) => {
    dispatch(setLDFlags(flags));
  },
};

export { actionMap };
