import { Machine } from 'xstate';

const app = {
  initial: 'init',
  onEntry: ['setCurrentLanguageToCookieValue', 'updateLaunchDarklyState'],
  states: {
    init: {
      on: {
        LOGOUT: 'loggedOut',
        LOGIN: 'loggedIn',
      },
    },
    loggedOut: {
      on: {
        LOGIN: [
          {
            target: 'loggedIn',
          },
        ],
      },
    },
    loggedIn: {
      on: {
        LOGOUT: 'loggedOut',
      },
      onEntry: ['startUserIdlingTracking'],
      onExit: ['reset', 'stopUserIdlingTracking'],
    },
  },
};

const loginForm = {
  initial: 'hidden',
  states: {
    hidden: {
      on: {
        LOGOUT: 'visible',
      },
      onEntry: 'fetchCriticalData',
    },
    visible: {
      on: {
        SUBMIT: 'submitting',
      },
    },
    submitting: {
      on: {
        LOGIN: 'hidden',
        ERROR: 'error',
        AMBIGUOUS: 'ambiguous',
        TOKEN: 'token',
      },
      onEntry: ['submitLoginForm'],
    },
    error: {
      on: {
        SUBMIT: 'submitting',
      },
    },
    ambiguous: {
      on: {
        SUBMIT: 'ambiguousSubmitting',
        REVERT: 'visible',
      },
      onExit: ['closeGlobalAlerts'],
    },
    ambiguousSubmitting: {
      on: {
        LOGIN: 'hidden',
        ERROR: 'ambiguousError',
        TOKEN: 'token',
      },
      onEntry: ['submitAmbiguousLoginForm'],
    },
    ambiguousError: {
      on: {
        SUBMIT: 'ambiguousSubmitting',
        REVERT: 'visible',
      },
      onExit: ['closeGlobalAlerts'],
    },
    token: {
      on: {
        SUBMIT: 'tokenSubmitting',
        REVERT: 'visible',
      },
      onExit: ['closeGlobalAlerts'],
    },
    tokenSubmitting: {
      on: {
        ERROR: 'tokenError',
        LOGIN: 'hidden',
      },
      onEntry: ['submitTokenLoginForm'],
    },
    tokenError: {
      on: {
        SUBMIT: 'tokenSubmitting',
        REVERT: 'visible',
      },
      onExit: ['closeGlobalAlerts'],
    },
  },
};

const voterProfile = {
  initial: 'closed',
  states: {
    opened: {
      on: {
        TOGGLE_VOTER_PROFILE: 'closed',
        LOGOUT: 'closed',
      },
    },
    closed: {
      on: {
        TOGGLE_VOTER_PROFILE: 'opened',
      },
    },
  },
};

const translations = {
  initial: 'init',
  states: {
    init: {
      on: {
        TRANSLATIONS_LOADING: 'loading',
      },
    },
    loading: {
      on: {
        TRANSLATIONS_SUCCESS: 'complete',
      },
    },
    complete: {
      on: {
        TRANSLATIONS_LOADING: 'loading',
      },
    },
  },
};

const languageMenu = {
  initial: 'closed',
  states: {
    opened: {
      on: {
        TOGGLE_LANGUAGE_MENU: 'closed',
        SET_CURRENT_LANGUAGE: 'closed',
        LOGIN: 'closed',
      },
    },
    closed: {
      on: {
        TOGGLE_LANGUAGE_MENU: 'opened',
      },
    },
  },
};

const preferenceForm = {
  initial: 'init',
  states: {
    init: {
      on: {
        SUBMIT: 'submitting',
      },
    },
    error: {
      on: {
        SUBMIT: 'submitting',
      },
    },
    submitting: {
      on: {
        SUCCESS: 'init',
        ERROR: 'error',
      },
      onEntry: ['submitPreferencesForm'],
    },
  },
};

const waitForCriticalData = {
  initial: 'waitForCriticalData',
  on: {
    LOGOUT: 'hidden',
  },
  states: {
    waitForCriticalData: {
      on: {
        CRITICAL_DATA_READY: 'preferences',
        ERROR: 'waitForCriticalData',
      },
    },
    preferences: {
      type: 'parallel',
      states: {
        preferenceForm,
      },
    },
  },
};

const preferences = {
  initial: 'hidden',
  states: {
    hidden: {
      on: {
        LOGIN: [{ target: 'waitForCriticalData' }],
      },
    },
    waitForCriticalData: waitForCriticalData,
  },
};

const globalAlert = {
  initial: 'closed',
  states: {
    closed: {
      on: {
        UPDATE_GLOBAL_ALERTS: 'open',
      },
    },
    open: {
      on: {
        CLOSE_ALL: 'closed',
        LOGOUT: 'closed',
      },
    },
  },
};

const statechart = {
  type: 'parallel',
  id: 'root',
  key: 'root',
  strict: true, // throws on invalid transitions
  states: {
    app,
    loginForm,
    voterProfile,
    translations,
    languageMenu,
    preferences,
    globalAlert,
  },
};

const machine = Machine(statechart);

// This is private, so it could break if it moves to something other than `idMap`.
// It's v convenient though, so note to future self, if you upgrade xstate and everything is hosed, this is maybe why.
const idMap = Object.keys(machine.idMap);

export { statechart, machine, idMap };
