import React from 'react';
import PropTypes from 'prop-types';

const EmailLink = ({ email }) => {
  const emailLink = email || 'help@ballottrax.com';

  return (
    <a
      href={`mailto:${emailLink}?Subject=Voter%20Login%20Support`}
      className="email-link"
    >
      {emailLink}
    </a>
  );
};

export default EmailLink;

EmailLink.propTypes = {
  email: PropTypes.string,
};
