import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
// import UnsubscribePage from 'components/pages/UnsubscribePage';
import UnsubscribePage from '../components/pages/UnsubscribePage';
import handleJsonErrors from '../apis/handleJsonErrors';
import processJsonResponse from '../apis/processJsonResponse';
import { standardHeaders } from '../apis/standardHeaders';

// The API sends back the following format: { voters_removed: INT, messages_removed: INT }
// We are counting any result (even voters_removed: 0) as success.
const unsubscribe = (key, email) => {
  return fetch('/api/voter/unsubscribe', {
    method: 'POST',
    headers: standardHeaders(),
    body: JSON.stringify({
      key,
      email,
    }),
  })
    .then(processJsonResponse)
    .then(handleJsonErrors);
};

const UnsubscribePageContainer = () => {
  useEffect(() => {
    ReactGA.set({ page: '/voter/unsubscribe' });
    ReactGA.send('pageview');
  }, []);
  return <UnsubscribePage unsubscribe={unsubscribe} />;
};

export default UnsubscribePageContainer;
