import React from 'react';
import PropTypes from 'prop-types';

const AmbiguousCancelButton = ({ onClick, _t }) => (
  <button
    className="ambiguous-cancel-button secondary-button"
    onClick={onClick}
    type={'button'} // necessary to avoid formik errors
  >
    {_t('CANCEL')}
  </button>
);

AmbiguousCancelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  _t: PropTypes.func.isRequired,
};

export default AmbiguousCancelButton;
