import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import LogoutButton from 'containers/LogoutButtonContainer';
import TelephoneLink from 'components/links/TelephoneLink';
import EmailLink from 'components/links/EmailLink';

export const VoterProfile = ({ county, user, _t }) => {
  const voterProfileCountyInfo = _t('VOTER_PROFILE_COUNTY_INFO', {
    disableWarning: true,
  }).join();

  return (
    <div className="voter-profile">
      <header className="voter-profile-header">
        {_t('Welcome to the easiest way to track your ballot!')}
      </header>

      <p className="voter-profile-ballottrax-description">
        {_t(
          '{{county}} and {{ballotTrax}} are working together to bring you the most up-to-date information about ' +
            'the status of your ballot. Here you can check your ballot status and change the way that BallotTrax ' +
            'will contact you!',
          { county: county.name, ballotTrax: _t('BallotTrax') },
        )}
      </p>

      <div className="voter-profile-content">
        <div className="voter-profile-county-logo">
          <img
            src={county.logoURL}
            alt={_t('logo for {{county}}', { county: county.name })}
          />
        </div>

        <section className="voter-profile-information voter-information">
          <div className="sub-header">{_t('YOUR VOTER PROFILE')}</div>
          <p>
            {user.firstName
              ? `${user.firstName} ${user.lastName}`
              : user.lastName}
          </p>
          <p>{user.address}</p>
          <p>
            {user.city} {user.state}, {user.zipcode}
          </p>
        </section>

        <section className="voter-profile-information county-information">
          {voterProfileCountyInfo !== 'VOTER_PROFILE_COUNTY_INFO' ? (
            <div dangerouslySetInnerHTML={{ __html: voterProfileCountyInfo }} />
          ) : (
            <>
              <div className="sub-header">
                {_t('CONTACT {{county}}', { county: county.name })}{' '}
              </div>
              <p>
                {_t(
                  'If you have any problems using {{ballotTrax}}, ' +
                    'or with the information provided, please contact {{county}}',
                  { county: county.name, ballotTrax: _t('BallotTrax') },
                )}
              </p>
              <br />
              <p className="voter-profile-county-phone">
                {_t('Phone')}:{' '}
                <TelephoneLink key={'phone-link'} phone={county.phone} />
              </p>
              <br />
              <p className="voter-profile-county-email">
                {_t('Email')}:{' '}
                <EmailLink key={'email-link'} email={county.email} />
              </p>
            </>
          )}
        </section>
      </div>

      <LogoutButton />
    </div>
  );
};

export default withTranslate(VoterProfile);

VoterProfile.propTypes = {
  _t: PropTypes.func.isRequired,
  county: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    logoURL: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
  }),
};
