import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import GlobalAlert from 'components/notifications/GlobalAlertNew';

class GlobalAlerts extends Component {
  constructor(props) {
    super(props);
    this.alertGroup = React.createRef();
  }

  componentDidMount() {
    this.alertGroup.current.focus();
  }

  render() {
    return (
      <Sticky className="global-alerts">
        <div className="alert-wrapper" ref={this.alertGroup} tabIndex={0}>
          {this.props.alerts.map((alert) => (
            <GlobalAlert
              key={`${alert.type}GlobalAlerts`}
              alert={alert}
              closeAll={this.props.closeAll}
              accountId={this.props.accountId}
            />
          ))}
        </div>
      </Sticky>
    );
  }
}

export default GlobalAlerts;

GlobalAlerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeAll: PropTypes.func.isRequired,
  accountId: PropTypes.number,
};
