import React, { ReactElement } from 'react';
import Switch from 'react-switch';

interface OptSwitchProps {
  optedIn: boolean;
  onClick: () => void;
  id: string;
  screenReaderPhrase?: string;
  _t: (phrase: string, replacements?: {}) => Array<string | ReactElement<any>>;
}

const OptSwitch = ({
  optedIn,
  onClick,
  id,
  _t,
  screenReaderPhrase,
}: OptSwitchProps) => {
  const screenReader = screenReaderPhrase || _t(`${id} opt in is ${optedIn}`);
  return (
    <>
      <Switch
        checked={optedIn}
        onChange={onClick}
        data-testid={'react-switch'}
      />
      <div className="screen-reader">{screenReader}</div>
    </>
  );
};

export default OptSwitch;
