// redux-xstate/index.js
// maintaining this for now because https://github.com/carlbarrdahl/redux-xstate/blob/master/.babelrc doesn't include
// support IE
export function getActions(states) {
  return Object.keys(states)
    .map((key) => {
      const state = states[key];
      const actions = Object.keys(state.on || {});

      return state.states ? getActions(state.states).concat(actions) : actions;
    })
    .reduce((a, b) => a.concat(b), [])
    .filter((key, pos, arr) => arr.indexOf(key) === pos);
}

export function createMiddleware(machine, actionMap) {
  const validActions = getActions(machine.config.states);

  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (validActions.includes(action.type)) {
        const state = getState();
        const nextState = machine.transition(
          state.machine.value,
          action,
          state,
        );

        dispatch({
          type: '@@machine/UPDATE_STATE',
          payload: nextState,
        });

        nextState.actions
          .map((key) => actionMap[key])
          .filter(Boolean)
          .forEach((fn) => fn(dispatch, state, action));
      }

      return next(action);
    };
}

export function createReducer(initialState) {
  return (state, { type, payload }) => {
    state = typeof state !== 'undefined' ? state : initialState;
    return type === '@@machine/UPDATE_STATE' ? payload : state;
  };
}
