import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import LoginForm from 'containers/LoginFormContainer';
import LoginInformation from 'components/LoginInformation';
import SupportNoticePreLogin from 'containers/SupportNoticePreLoginContainer';
import Footer from 'components/Footer';
import Hero from 'components/Hero';
import ReactGA from 'react-ga4';
import Outage from './Outage';
import { useFlags } from 'launchdarkly-react-client-sdk';

const HomePage = ({ isLoggedIn, customBackgroundImage, logo, accountId }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  useEffect(() => {
    ReactGA.set({ page: '/voter/login' });
    ReactGA.send('pageview');
  }, []);
  const { voterOutage } = useFlags();

  if (code && isLoggedIn) {
    return <Navigate to={`/confirm-email?code=${code}`} replace />;
  }
  return isLoggedIn ? (
    <Navigate to={'/confirmCheck'} />
  ) : (
    <main
      className="home-page-layout"
      id="main-content"
      data-testid={'home-page'}
    >
      <Hero customBackgroundImage={customBackgroundImage} logo={logo} />
      <div className="login-container">
        {voterOutage && <Outage />}
        <LoginInformation accountId={accountId} />
        <LoginForm />
        <SupportNoticePreLogin />
        <Footer />
      </div>
    </main>
  );
};

export default HomePage;

HomePage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  customBackgroundImage: PropTypes.string,
  logo: PropTypes.string,
  accountId: PropTypes.number,
};
