import { PaginationLinks } from '../interfaces/Pagination';

export const getPaginationLinks = (data: any) => {
  if (!data) {
    return {
      page: 1,
      pageCount: 1,
    };
  }

  const links = data._links;
  const paginations: PaginationLinks = {
    page: data.page,
    pageCount: data.page_count,
  };

  type PaginationLinksNames = 'first' | 'last' | 'next' | 'prev';
  const linksWeCareAbout: PaginationLinksNames[] = [
    'first',
    'last',
    'next',
    'prev',
  ];

  linksWeCareAbout.forEach((name) => {
    if (links[name]) {
      paginations[name] = links[name].href;
    }
  });

  if (data.page_size) {
    paginations.pageSize = data.page_size;
  }

  if (data.total_items) {
    paginations.totalItems = data.total_items;
  }

  return paginations;
};

const handlePagination = (setPagination: (links: PaginationLinks) => void) => {
  return function (data: any) {
    setPagination(getPaginationLinks(data));
    return data;
  };
};

export default handlePagination;
