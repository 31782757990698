import React from 'react';
import PropTypes from 'prop-types';
import StateMatcher from 'containers/StateMatcherContainer';
import Loading from 'components/shared/Loading';
import withTranslate from 'components/withTranslate';
import { Field, ErrorMessage } from 'formik';
import ErrorMessageRenderer from '../notifications/ErrorMessageRenderer';

const validate = (value) => {
  if (value === '') {
    return 'Token is required.';
  }
};

export const TokenForm = (props) => {
  const { _t } = props;
  return (
    <div className="token-container">
      <div className="token-title-section" id={'token-section'}>
        {/*"Enhanced Security Token Required" doesn't exist in translations*/}
        <p className={'header'}>
          {_t('Enhanced Security Token Required', { disableWarning: true })}
        </p>
        <p
          className={'subheader'}
          role={'region'}
          aria-labelledby={'token-section'}
          aria-live={'polite'}
        >
          {_t(
            'Your county requires you to enter an extra identifying piece of information. Please visit your county or state branded site for more information.',
            { disableWarning: true },
          )}
        </p>
      </div>
      <StateMatcher matches={'loginForm.tokenSubmitting'}>
        <div className="token-loading">
          <Loading />
        </div>
      </StateMatcher>
      <StateMatcher matches={['loginForm.token', 'loginForm.tokenError']}>
        <section>
          <label htmlFor={'token'}>
            {_t('Security Token', { disableWarning: true })}
          </label>
          <Field
            autoFocus
            id={'token'}
            name={'token'}
            placeholder={_t('Enter Security Token Here', {
              disableWarning: true,
            })}
            onKeyDown={(e) => props.escHandler(e)}
            validate={validate}
            aria-describedby={'token-error'}
          />
          <ErrorMessage
            name={'token'}
            render={(error) => (
              <ErrorMessageRenderer
                error={error}
                field={'token'}
                id={'token-error'}
              />
            )}
          />
        </section>
      </StateMatcher>
    </div>
  );
};

export default withTranslate(TokenForm);

TokenForm.propTypes = {
  _t: PropTypes.func.isRequired,
  escHandler: PropTypes.func.isRequired,
};
