import React from 'react';
import PastBallot from 'components/archivedBallots/PastBallot';
import { archivedBallot } from '../../interfaces/archivedBallots';

export interface ArchivedBallotsListProps {
  ballots: archivedBallot[];
}

export const ArchivedBallotsList = ({ ballots }: ArchivedBallotsListProps) => {
  return (
    <div className="theme-2018 past-ballots-list dashboard-card">
      {ballots.map((ballot) => (
        <PastBallot
          key={ballot.ballot_id}
          name={ballot.election_name}
          displayStatus={ballot.display_name}
          status={ballot.status}
        />
      ))}
    </div>
  );
};

export default ArchivedBallotsList;
