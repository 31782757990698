import React from 'react';
import PropTypes from 'prop-types';
import BallotInfoIcon from 'components/icons/BallotInfoIcon';
import BallotSummary from 'components/BallotSummary';
import BallotDetail from 'components/BallotDetail';

const getIconType = (datetime, isStandardFlow, status) => {
  if (status === 'rejected-curable') {
    return 'caution';
  }
  if (status === 'reprinted') {
    return 'completed';
  }
  if (!isStandardFlow) {
    return 'halted';
  }
  if (datetime) {
    return 'completed';
  }
  return 'incomplete';
};

// *svg-cleanup*
const BallotStep = ({
  stepDetails: {
    display_name,
    datetime,
    display_description,
    is_standard_flow,
    status,
  },
}) => (
  <div
    className={`ballot-step-wrapper ${!datetime ? 'future' : ''}`}
    data-testid="ballot-step"
  >
    <div className="timeline-style">
      <BallotInfoIcon
        iconType={getIconType(datetime, is_standard_flow, status)}
      />
    </div>
    <div className="step-style">
      <BallotSummary statusText={display_name} date={datetime} />
      <BallotDetail text={display_description} />
    </div>
  </div>
);

export default BallotStep;

BallotStep.propTypes = {
  stepDetails: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    datetime: PropTypes.string,
    display_description: PropTypes.string.isRequired,
  }),
};
