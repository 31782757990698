import { initialAlertsNew } from 'reducers/initialAlerts';

const alertsReducerNew = (state, action) => {
  state = typeof state !== 'undefined' ? state : initialAlertsNew;

  switch (action.type) {
    case 'UPDATE_GLOBAL_ALERTS': {
      return [
        action.alerts.success,
        action.alerts.notice,
        action.alerts.error,
      ].filter(Boolean); // filters out keys that result in undefined
    }
    case 'CLOSE_ALL':
      return initialAlertsNew;
    default:
      return state;
  }
};

export default alertsReducerNew;
