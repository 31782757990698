import React from 'react';
import PropTypes from 'prop-types';

// as this gets larger, we can abstract it to another file/utility
const types = {
  globe: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm7 6h-3c-.3-1.3-.8-2.5-1.4-3.6A8 8 0 0 1 18.9 8zm-7-4a14 14 0 0 1 2 4h-4a14 14 0 0 1 2-4zM4.3 14a8.2 8.2 0 0 1 0-4h3.3a16.5 16.5 0 0 0 0 4H4.3zm.8 2h3c.3 1.3.7 2.5 1.3 3.6A8 8 0 0 1 5.1 16zm3-8H5a8 8 0 0 1 4.3-3.6C8.8 5.5 8.4 6.7 8 8zM12 20a14 14 0 0 1-2-4h4a14 14 0 0 1-2 4zm2.3-6H9.7a14.7 14.7 0 0 1 0-4h4.6a14.6 14.6 0 0 1 0 4zm.3 5.6c.6-1.2 1-2.4 1.4-3.6h3a8 8 0 0 1-4.4 3.6zm1.8-5.6a16.5 16.5 0 0 0 0-4h3.3a8.2 8.2 0 0 1 0 4h-3.3z" />
    </g>
  ),
  loading: (
    <g>
      <defs>
        <path id="a" d="M0 0h24v24H0z" />
      </defs>

      <clipPath id="b">
        <use href="#a" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#b)"
        d="M11 5.08V2c-5 .5-9 4.81-9 10s4 9.5 9 10v-3.08c-3-.48-6-3.4-6-6.92s3-6.44 6-6.92zM18.97 11H22c-.47-5-4-8.53-9-9v3.08C16 5.51 18.54 8 18.97 11zM13 18.92V22c5-.47 8.53-4 9-9h-3.03c-.43 3-2.97 5.49-5.97 5.92z"
      />
    </g>
  ),
  carat: (
    <g>
      <path d="M7.4 8.6l4.6 4.6 4.6-4.6L18 10l-6 6-6-6 1.4-1.4z" />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </g>
  ),
  error: (
    <g>
      <path
        fill="#fff"
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      />
    </g>
  ),
  notice: (
    <g>
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        fill="#fff"
        d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16z"
      />
    </g>
  ),
  success: (
    <g>
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        fill="#fff"
        d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
      />
    </g>
  ),
  accepted: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm-2 15l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" />
    </g>
  ),
  rejected: (
    <g>
      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm5 13.6L15.6 17 12 13.4 8.4 17 7 15.6l3.6-3.6L7 8.4 8.4 7l3.6 3.6L15.6 7 17 8.4 13.4 12l3.6 3.6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  ),
  incomplete: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm-2 15l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" />
    </g>
  ),
  information: (
    <g>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
    </g>
  ),
};

const Icon = ({ type, className = '' }) => (
  <svg
    title={`${type} icon`}
    className={`svg-icon ${type}-icon ${className}`.trim()}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    role={'img'}
  >
    {types[type]}
  </svg>
);

export default Icon;

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};
