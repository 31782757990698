import { Action, DeliveryTypesAction } from '../interfaces/action';

const initialState: string[] = [];

const deliveryTypesReducer = (state = initialState, action: Action) => {
  if (action.type === 'FETCH_DELIVERY_TYPES') {
    const deliveryAction = action as DeliveryTypesAction;

    return deliveryAction.deliveryTypes.map((option) => option.delivery_type);
  }

  return state;
};

export default deliveryTypesReducer;
