import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from 'components/withTranslate';

export const FieldNote = ({ message, replacements, _t }) => {
  const wrapperStyle = {
    whiteSpace: 'pre-line',
  };

  return (
    <div
      className={'field-message'}
      style={wrapperStyle}
      role="region"
      aria-live="polite"
    >
      {_t(message, replacements)}
    </div>
  );
};

export default withTranslate(FieldNote);

FieldNote.propTypes = {
  message: PropTypes.string.isRequired,
  replacements: PropTypes.object,
  _t: PropTypes.func.isRequired,
};
