import jsonMap from 'models/jsonMap';
import { preferenceErrorMessages } from '../components/notifications/preferenceErrorMessages';

class PreferenceModel {
  constructor(oldValues, newValues) {
    this._oldValues = oldValues;
    this._newValues = newValues;
  }

  toJSON() {
    let fields = getChangedFields(this._newValues, this._oldValues);

    if (Object.keys(fields).includes('limitHoursOptIn')) {
      fields = transformLimitHours(fields);
    }

    return format(fields);
  }
}

export const getChangedFields = (newValues, oldValues) => {
  // NOTE: *only* fields present in newValues will appear in diff
  return Object.keys(newValues)
    .filter((key) => newValues[key] !== oldValues[key])
    .reduce(
      (diffObj, key) => Object.assign(diffObj, { [key]: newValues[key] }),
      {},
    );
};
// set times to null if opted out
const transformLimitHours = (fields) => {
  let limitStart = fields.limitStart || '08:00';
  let limitEnd = fields.limitEnd || '20:00';

  if (fields.limitHoursOptIn === false) {
    limitStart = null;
    limitEnd = null;
  }

  return { ...fields, limitStart, limitEnd };
};

//filter out special characters before validation
export const filterPhoneFields = (fields) => {
  const allowed = ['sms', 'voice'];
  const filtered = Object.keys(fields)
    .filter((field) => allowed.includes(field))
    .map((key) => ({ [key]: fields[key].replace(/[^a-z0-9]/gi, '') }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

  return { ...fields, ...filtered };
};

//validate required fields
export const requiredFieldValidator = (fields) => {
  let errors = {};

  if (fields.emailOptIn === true && fields.email.trim() === '') {
    errors.email = [preferenceErrorMessages.email.required];
  }

  if (fields.smsOptIn === true && fields.sms.trim() === '') {
    errors.sms = [preferenceErrorMessages.sms.required];
  }

  if (fields.voiceOptIn === true && fields.voice.trim() === '') {
    errors.voice = [preferenceErrorMessages.voice.required];
  }

  return errors;
};

// create API friendly object
const format = (fields) => {
  const allowed = Object.keys(jsonMap);
  return Object.keys(fields)
    .filter((key) => allowed.includes(key))
    .map((key) => {
      const transform = jsonMap[key];
      return transform(fields[key]);
    })
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export default PreferenceModel;
