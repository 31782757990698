import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import headersCarat from 'img/cb_headers_carat.png';

export const CurrentBallotDetailsHeader = ({ isOpen, toggle, _t }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      toggle();
    }
  };

  return (
    <div
      onClick={toggle}
      className={`current-ballot-details-header details-collapsable-header ${
        isOpen ? 'header-opened' : 'header-closed'
      }`}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <span className="header-text">{_t('Details')}</span>
      <img src={headersCarat} alt={'Toggle details icon'} />
    </div>
  );
};

export default withTranslate(CurrentBallotDetailsHeader);

CurrentBallotDetailsHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  _t: PropTypes.func.isRequired,
};
