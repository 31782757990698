import React from 'react';
import { withFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import StateMatcher from 'containers/StateMatcherContainer';
import Loading from 'components/shared/Loading';
import PreferencesTitleSection from 'components/icons/PreferencesTitleSection';
import ContactLanguageDropdown from 'components/inputs/ContactLanguageDropdown';
import RenderIf from 'components/shared/RenderIf';
import withTranslate from 'components/withTranslate';
import validatePreferences from 'components/validators/validatePreferences';
import EmailField from 'components/forms/EmailField';
import SmsField from './SmsField';
import VoiceField from './VoiceField';
import LimitHoursField from './LimitHoursField';
import PreferenceErrorGatherer from './PreferenceErrorGatherer';
import { checkUserValidationMessages } from '../validators/checkUserApiValidationMessages';
import { Footer } from '../Footer';

export const PreferencesForm = ({
  values,
  _t,
  deliveryTypes,
  errors,
  touched,
  shouldRedirect,
  setValues,
  isConfirmed,
  user,
  handleChange,
  availableLanguages,
  formatter,
  machine,
  onDashboard,
  handleSubmit,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  if (deliveryTypes.length === 0) {
    return <Navigate to={'/dashboard'} replace />;
  }

  return (
    <div data-testid={'preferences-page'}>
      <RenderIf condition={isConfirmed && !!shouldRedirect} redirect>
        <Navigate to={'/dashboard'} replace />
      </RenderIf>
      <StateMatcher
        matches={[
          'preferences.waitForCriticalData.waitForCriticalData',
          'preferences.waitForCriticalData.preferences.preferenceForm.submitting',
        ]}
      >
        <Loading />
      </StateMatcher>
      <StateMatcher
        matches={[
          'preferences.waitForCriticalData.preferences.preferenceForm.init',
          'preferences.waitForCriticalData.preferences.preferenceForm.error',
        ]}
      >
        <form className={'preferences-form'} onSubmit={handleFormSubmit}>
          <div className="form-layout dashboard-card">
            <RenderIf condition={deliveryTypes.includes('email')} email>
              <EmailField
                values={values}
                setValues={setValues}
                user={user}
                errors={errors}
                touched={touched}
                machine={machine}
              />
            </RenderIf>

            <RenderIf condition={deliveryTypes.includes('sms')}>
              <SmsField
                values={values}
                setValues={setValues}
                user={user}
                errors={errors}
                touched={touched}
              />
            </RenderIf>

            <RenderIf condition={deliveryTypes.includes('voice')}>
              <VoiceField
                values={values}
                setValues={setValues}
                errors={errors}
                touched={touched}
              />
            </RenderIf>

            <LimitHoursField
              values={values}
              setValues={setValues}
              user={user}
              formatter={formatter}
            />

            <RenderIf condition={availableLanguages.length > 1} language>
              <section className="preference-section contact-language-section">
                <PreferencesTitleSection
                  type={'language'}
                  title={_t('Preferred Language for Messages')}
                />
                <ContactLanguageDropdown
                  onChange={handleChange}
                  availableLanguages={availableLanguages}
                  currentLanguage={values.languagePreference}
                />
              </section>
            </RenderIf>

            <div className="button-layout">
              <button
                className="reset-button secondary-button"
                type="button"
                onClick={() => setValues({ ...user })}
              >
                {_t('Reset Form')}
              </button>
              <button className="submit-button primary-button" type="submit">
                {_t(isConfirmed ? 'Update' : 'CONFIRM')}
              </button>
            </div>
          </div>
        </form>
      </StateMatcher>
      <RenderIf condition={!isConfirmed && !onDashboard}>
        <Footer _t={_t} />
      </RenderIf>
    </div>
  );
};

const PreferencesWithErrors = (props) => {
  return (
    <PreferenceErrorGatherer
      errors={props.errors}
      user={props.user}
      verifyUserData={checkUserValidationMessages}
      touched={props.touched}
      render={(gatheredErrors) => (
        <PreferencesForm {...props} errors={gatheredErrors} />
      )}
    />
  );
};

const FormikForm = withFormik({
  mapPropsToValues: ({ user, isConfirmed }) => {
    return {
      email: user.email,
      sms: user.sms,
      voice: user.voice,
      limitStart: user.limitStart,
      limitEnd: user.limitEnd,
      emailOptIn: user.emailOptIn,
      smsOptIn: user.smsOptIn,
      voiceOptIn: user.voiceOptIn,
      limitHoursOptIn: user.limitHoursOptIn || !isConfirmed,
      languagePreference: user.languagePreference,
      isValidEmail: user.isValidEmail,
    };
  },
  validate: (values, props) => {
    return validatePreferences(values, props.user);
  },
  handleSubmit: (values, bag) => {
    bag.props.handleSubmit(values, bag);
  },
})(PreferencesWithErrors);

export default withTranslate(FormikForm);

PreferencesForm.propTypes = {
  values: PropTypes.object.isRequired,
  _t: PropTypes.func.isRequired,
  formatter: PropTypes.func.isRequired,
  deliveryTypes: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  shouldRedirect: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  isConfirmed: PropTypes.bool,
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  availableLanguages: PropTypes.array.isRequired,
  touched: PropTypes.object.isRequired,
  machine: PropTypes.object.isRequired,
  onDashboard: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

PreferencesWithErrors.propTypes = {
  errors: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};
