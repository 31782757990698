import { standardHeaders } from './standardHeaders';
import processJsonResponse from './processJsonResponse';
import handleJsonErrors from './handleJsonErrors';

export const loginWithBallotGuid = (guid: string, dob: string) => {
  // Adding in URL of the server we're on so Safari doesn't get stupid and try to call into the http version of the
  // site with a relative URL, causing the login to fail sometimes if the QR scan result goes into a tab that already
  // had BallotTrax loaded
  const url = window.location.href;
  const [protocol, , server] = url.split('/');

  return fetch(`${protocol}//${server}/api/voter/ballot-login`, {
    method: 'POST',
    headers: standardHeaders(),
    body: JSON.stringify({
      ballot_guid: guid,
      date_of_birth: dob,
    }),
  })
    .then(processJsonResponse)
    .then(handleJsonErrors);
};

export default loginWithBallotGuid;
