import React, { useEffect, useState } from 'react';
import loginWithBallotGuid from '../apis/ballotLogin';
import ReactGA from 'react-ga4';
import getBaseUrl from '../util/baseUrl';
import Loading from '../components/shared/Loading';
import withTranslate from '../components/withTranslate';
import LanguageDropdown from './LanguageDropdownContainer';
import defaultBackgroundImage from '../img/hero.jpg';

function QRLogin({ branding, _t }) {
  const params = new URLSearchParams(document.location.search.substring(1));
  const guid = params.get('guid');
  const [dob, setDob] = useState('');
  const [errorText, setErrorText] = useState('');

  const theBackground = branding.bg_url || defaultBackgroundImage;

  useEffect(() => {
    ReactGA.set({ page: '/voter/qr-login' });
    ReactGA.send('pageview');
  }, []);

  useEffect(() => {
    if (!guid) {
      window.location.href = `${getBaseUrl()}/voter`;
    }

    fetch(`/api/voter/qr-validate/${guid}`).then((res) => {
      // 404 = invalid
      // 410 = expired
      // Both of those are !ok and we are bouncing to login in both cases
      if (!res.ok) {
        window.location.href = `${getBaseUrl()}/voter`;
      }
      // return res.json()
    });
  }, [guid]);

  function doSubmit(e) {
    e.preventDefault();
    if (!dob) {
      setErrorText('Date of Birth must not be empty');
      return;
    }
    loginWithBallotGuid(guid, dob)
      .then(() => {
        ReactGA.event({
          category: 'qr-login',
          action: 'successful guid login',
        });
        window.location.href = `${getBaseUrl()}/voter/dashboard`;
      })
      .catch((error) => {
        ReactGA.event({
          category: 'qr-login',
          action: `qr login error: ${error.message}`,
        });
        if (
          error.message ===
            'Application Error: 400: Bad Request - Date of birth did not match' ||
          error.message ===
            'Application Error: 422: Unprocessable Entity - Failed Validation'
        ) {
          setErrorText(
            _t(
              'The date of birth you entered does not match the QR code you scanned. Please try again, or log in on our full site at {{subdomain}}.',
              {
                subdomain: (
                  <a key="link" href={`${getBaseUrl()}/voter`}>
                    {_t('BallotTrax')}
                  </a>
                ),
              },
            ),
          );
        } else {
          window.location.href = `${getBaseUrl()}/voter`;
        }
      });
  }

  return (
    <div className={'qr-login-page'}>
      <div
        className={'outer-qr-login-container'}
        style={{
          backgroundImage: `url('${theBackground}')`,
        }}
      >
        <div className={'qr-language-dropdown-container'}>
          <LanguageDropdown />
        </div>
        <div className={'qr-blue-background'}>
          <div className={'qr-logo-container'}>
            {branding.logo_url ? (
              <img
                src={branding.logo_url}
                alt={'county logo'}
                className={'account-logo'}
              />
            ) : (
              <Loading />
            )}
          </div>

          <form className={'qr-login-form'}>
            <div className={'qr-login-container'}>
              <span className={'dob-text'}>
                {_t(
                  'Please enter your date of birth to see the status of your ballot and sign up for ballot status alerts.',
                )}
              </span>
              <label htmlFor={'dob'} className={'dob-label'}>
                {_t('DATE OF BIRTH')}
              </label>
              <input
                type={'text'}
                name={'dob'}
                input={'dob'}
                className={'dob-input'}
                value={dob}
                onChange={(e) => {
                  setErrorText('');
                  setDob(e.target.value);
                }}
              />
              <p className={'dob-example'}>
                {_t("Example: 'MM-DD-YYYY' '07-20-1969'")}
              </p>
              {errorText && (
                <div className={'dob-text qr-error-text'}>{errorText}</div>
              )}
              <button
                type={'submit'}
                onClick={doSubmit}
                className={'login-button primary-button'}
              >
                {_t('Register / Log In')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withTranslate(QRLogin);
