export default function sanitize(text) {
  const map = {
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '/': '&#x2F;',
  };
  const reg = /[<>"/]/gi;
  return text.replace(reg, (match) => map[match]);
}
