import React from 'react';
import withTranslate from '../withTranslate';

export const ContactPreferencesJumpLink = ({ _t }) => {
  return (
    <div className="global-alerts">
      <div className="alert-wrapper">
        <div className="contact-preferences-alert">
          <svg
            className="contact-preferences-alert-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            role="img"
            aria-hidden="true"
          >
            <path
              fill="#ffffff"
              d="M23 7V13H21V7M21 15H23V17H21M12 2A2 2 0 0 0 10 4A2 2 0 0 0 10 4.29C7.12 5.14 5 7.82 5 11V17L3 19V20H21V19L19 17V11C19 7.82 16.88 5.14 14 4.29A2 2 0 0 0 14 4A2 2 0 0 0 12 2M10 21A2 2 0 0 0 12 23A2 2 0 0 0 14 21Z"
            />
          </svg>
          <span>
            <a className="contact-preferences-link" href="#contact-preferences">
              {_t('Sign Up for Ballot Status Alerts')}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withTranslate(ContactPreferencesJumpLink);
