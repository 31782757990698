import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import DropdownArrow from '../../img/arrow_dropdown_prefs.png';

const EndTimeInputField = (props) => {
  return (
    <select
      name="limitEnd"
      className="time-input-field"
      value={props.value}
      onChange={props.onChange}
      id={props.id}
      style={{ background: `url(${DropdownArrow}) no-repeat right` }}
    >
      {props.times.map((time, i) => (
        <option value={time.format('HH:mm')} key={i}>
          {time.format('LT')}
        </option>
      ))}
    </select>
  );
};

EndTimeInputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  times: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  id: PropTypes.string.isRequired,
};

export default EndTimeInputField;
