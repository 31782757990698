import { Alert, Message } from '../../interfaces/alert';
import preferenceValidationErrorHandler, {
  preferenceValidationErrorHandlerNew,
} from './preferenceValidationErrorHandler';
import contactTimeNotDifferentHandler from './contactTimeNotDifferentHandler';
import { ValidationMessages } from '../../interfaces/validationMessages';

export const preferenceErrorMessage = (error: {
  detail: string;
  validation_messages?: {
    [field: string]: { [key: string]: string };
  };
}): Alert => {
  const defaultMessage = {
    label: 'Error',
    message:
      'Oops! Something went wrong on our end. Please try your latest action again.',
  };

  // The API returns validation messages for (1) validation errors, (2) sms opt-out, and (3) bad emails
  // We map all these to preferenceValidationErrorHandler since that handles responses with a validation_messages field
  const errorHandlerMap = {
    'Failed Validation': preferenceValidationErrorHandler,
    'SMS Phone number has opted out of text messages':
      preferenceValidationErrorHandler,
    'Email validation failed for this email address. Please change email address or uncheck opt-in.':
      preferenceValidationErrorHandler,
    'Contact between times must be different': contactTimeNotDifferentHandler,
  };

  const errorHandler = errorHandlerMap[error.detail];

  return errorHandler
    ? errorHandler(error.validation_messages)
    : [defaultMessage];
};

export default preferenceErrorMessage;

export const preferenceErrorMessageNew = (error: {
  detail: string;
  validation_messages?: ValidationMessages;
}): Message[] => {
  const defaultMessage: Message = {
    text: 'Oops! Something went wrong on our end. Please try your latest action again.',
    important: false,
  };

  // The API returns validation messages for (1) validation errors, (2) sms opt-out, and (3) bad emails
  // We map all these to preferenceValidationErrorHandler since that handles responses with a validation_messages field
  const errorHandlerMap = {
    'Failed Validation': preferenceValidationErrorHandlerNew,
    'SMS Phone number has opted out of text messages':
      preferenceValidationErrorHandlerNew,
    'Email validation failed for this email address. Please change email address or uncheck opt-in.':
      preferenceValidationErrorHandlerNew,
    'Contact between times must be different': () => [
      {
        text: 'Invalid contact times',
        important: true,
      },
      {
        text: 'Contact between times must be different',
        important: false,
      },
    ],
  };

  const errorHandler = errorHandlerMap[error.detail];

  return errorHandler
    ? errorHandler(error.validation_messages)
    : [defaultMessage];
};
