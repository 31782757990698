import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import defaultBackgroundImage from '../img/hero.jpg';

export const Hero = ({ _t, customBackgroundImage, logo }) => {
  const customLogoUrl = logo;
  const theBackground = customBackgroundImage || defaultBackgroundImage;
  const [backgroundImage, setBackgroundImage] = useState(
    `url('${theBackground}')`,
  );

  // As we wait for the branding api call to resolve, customBackgroundImage (branding.bg_url)
  // is set to an all white gif. After the api call resolves, customBackgroundImage will be set
  // to the branded url and will possibly be null, which is why we need the defaultBackgroundImage
  // when we are setting theBackground value (above). This useEffect is listening for changes to
  // the customBackgroundImage value--is it the white gif, a branded url, or null?
  useEffect(() => {
    setBackgroundImage(`url('${theBackground}')`);
  }, [theBackground]);

  // Do not render the background image if the screen width is less than or equal to 425px (~ mobile phone).
  useEffect(() => {
    let mediaQuery = window.matchMedia('(max-width: 425px)');
    const handleBackgroundChange = (e) => {
      if (e.matches) {
        setBackgroundImage('none');
      } else {
        setBackgroundImage(`url('${theBackground}')`);
      }
    };
    mediaQuery.addEventListener('change', handleBackgroundChange);

    return () =>
      mediaQuery.removeEventListener('change', handleBackgroundChange);
  }, [theBackground]);

  return (
    <section
      className="hero"
      style={{
        backgroundImage: backgroundImage,
      }}
    >
      <div className="hero-content">
        <img className="logo" src={customLogoUrl} alt="County Logo" />
        <div className="line" />
        <div className="header-container">
          <div
            className="hero-header"
            role={'heading'}
            aria-level="2"
            dangerouslySetInnerHTML={{
              __html: _t('Trust That Your Vote Counts!').join(),
            }}
          />
          <p
            className="hero-sub-header"
            dangerouslySetInnerHTML={{
              __html: _t(
                'Track the status of your mail-in ballot, from printed to accepted',
              ).join(),
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default withTranslate(Hero);

Hero.propTypes = {
  _t: PropTypes.func.isRequired,
  customBackgroundImage: PropTypes.string,
  logo: PropTypes.string,
};
