import { connect } from 'react-redux';
import VoterProfile from 'components/VoterProfile';

const mapStateToProps = ({ branding, user }) => ({
  county: {
    name: branding.name,
    phone: branding.contact_phone,
    email: branding.contact_email,
    logoURL: branding.logo_url,
  },
  user: {
    firstName: user.firstname,
    lastName: user.lastname,
    address: user.address,
    city: user.city,
    state: user.state,
    zipcode: user.zipcode,
  },
});

export default connect(mapStateToProps)(VoterProfile);
