import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StateMatcher from 'containers/StateMatcherContainer';
import NavBar from 'containers/NavBarContainer';
import Loading from 'components/shared/Loading';
import VoterProfile from 'containers/VoterProfileContainer';
import Modals from 'containers/ModalContainer';
import GlobalAlerts from 'containers/GlobalAlertsContainer';
import UnsubscribePage from './containers/UnsubscribePageContainer';
import PrivacyPage from 'containers/PrivacyPageContainer';
import FAQPage from 'containers/FAQPageContainer';
import MobileNav from 'containers/MobileNavContainer';
import QRLogin from './containers/QRLoginContainer';
import ContactPreferencesJumpLink from './components/notifications/ContactPreferencesJumpLink';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SmsPolicyPage from './containers/SmsPageContainer';
import HomePage from './containers/HomePageContainer';
import PrivateRoute from './containers/PrivateRouteContainer';
import DashboardPage from './components/pages/DashboardPage';
import PreferencesPage from './components/Preferences';
import ConfirmCheck from './containers/ConfirmCheckContainer';
import NotFoundPage from './components/pages/NotFoundPage';

const App = ({ setLanguageFromQueryParam, setLDFlags }) => {
  useEffect(() => setLanguageFromQueryParam(), [setLanguageFromQueryParam]);
  const ldFlags = useFlags();

  useEffect(() => {
    setLDFlags(ldFlags);
  }, [ldFlags, setLDFlags]);

  return (
    <div className={'theme-orig'}>
      <Router basename={'/voter'}>
        <Routes>
          <Route path="/unsubscribe" element={<UnsubscribePage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/sms-policy" element={<SmsPolicyPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/qr-login" element={<QRLogin />} />
          <Route path="*" element={<MainContent />} />
        </Routes>
      </Router>
    </div>
  );
};

export const MainContent = () => (
  <div id="app">
    <StateMatcher matches={'app.init'}>
      <Loading />
    </StateMatcher>
    <StateMatcher matches={['app.loggedIn', 'app.loggedOut']}>
      <StateMatcher matches={'app.loggedOut'}>
        <a href="#firstname" className="skip-link" aria-live="assertive">
          Skip to form
        </a>
      </StateMatcher>

      <StateMatcher matches={'app.loggedIn'}>
        <a
          href="#email"
          className="skip-link skip-link-logged-in"
          aria-live="assertive"
        >
          Skip to form
        </a>
      </StateMatcher>

      <Modals />
      <NavBar />

      <StateMatcher matches={'voterProfile.closed'}>
        <StateMatcher matches={'app.loggedIn'}>
          <ContactPreferencesJumpLink />
        </StateMatcher>
      </StateMatcher>

      <StateMatcher matches={'globalAlert.open'}>
        <div aria-live="assertive" aria-atomic="true" aria-relevant="all">
          <GlobalAlerts />
        </div>
      </StateMatcher>

      {/* The following StateMatcher reliant components could be made into one component. Toggleables? */}
      <StateMatcher matches={'app.loggedIn'}>
        <StateMatcher matches={'voterProfile.opened'}>
          <VoterProfile />
          <MobileNav />
        </StateMatcher>
      </StateMatcher>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<HomePage />} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route index element={<DashboardPage />} />
        </Route>
        <Route path="/preferences" element={<PrivateRoute />}>
          <Route index element={<PreferencesPage />} />
        </Route>
        <Route path="/confirmCheck" element={<PrivateRoute />}>
          <Route index element={<ConfirmCheck />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </StateMatcher>
  </div>
);

export default App;
