import React from 'react';
import withTranslate from 'components/withTranslate';
import Icon from 'components/icons/Icon';
import PropTypes from 'prop-types';

export const LanguageDropdown = ({
  availableLanguages,
  currentLanguage,
  setCurrentLanguage,
  abbreviations,
  isLoggedIn,
  _t,
}) => {
  const handleChange = (e) => {
    setCurrentLanguage(e.target.value, isLoggedIn);
  };

  function getAbbreviation(language) {
    return abbreviations[language] || '??';
  }

  return (
    <section className="language-dropdown">
      <Icon type="globe" className="nav-globe-icon" />
      <label htmlFor="languages" className="screen-reader">
        Languages
      </label>
      <select onChange={handleChange} id="languages" value={currentLanguage}>
        <option disabled className="languages-title">
          {_t('Languages')}
        </option>
        {availableLanguages.map((availableLanguage, index) => (
          <option
            key={index}
            value={availableLanguage.language}
            className="available-languages"
          >
            {getAbbreviation(availableLanguage.language).toUpperCase()} -{' '}
            {_t(availableLanguage.language)}
          </option>
        ))}
      </select>
    </section>
  );
};

export default withTranslate(LanguageDropdown);

LanguageDropdown.propTypes = {
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  currentLanguage: PropTypes.string.isRequired,
  setCurrentLanguage: PropTypes.func.isRequired,
  _t: PropTypes.func.isRequired,
  abbreviations: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};
