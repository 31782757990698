const initialState = {
  currentlyOpenModal: null,
};

const modalReducer = (state, action) => {
  state = typeof state !== 'undefined' ? state : initialState;

  switch (action.type) {
    case 'OPEN_MODAL':
      return action.modal;
    case 'CLOSE_MODAL':
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
