import * as React from 'react';

export const NavButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => (
  <button className="update-preferences-button" type="submit" onClick={onClick}>
    {label}
  </button>
);

export default NavButton;
