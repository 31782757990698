const initialState = {
  name: 'BallotTrax',
  bg_url: '/img/white.gif',
};

const brandingReducer = (state, action) => {
  state = typeof state !== 'undefined' ? state : initialState;

  if (action.type === 'BRANDING_SUCCESS') {
    return action.branding;
  }

  return state;
};

export default brandingReducer;
