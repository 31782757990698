const initialState = {
  currentLanguage: 'english',
  availableLanguages: [
    {
      language: 'english',
      description: 'english',
    },
  ],
};

const languageReducer = (state, { type, payload }) => {
  state = typeof state !== 'undefined' ? state : initialState;

  switch (type) {
    case 'SET_CURRENT_LANGUAGE':
      return {
        ...state,
        currentLanguage: payload.currentLanguage,
      };

    case 'SET_AVAILABLE_LANGUAGES':
      return {
        ...state,
        availableLanguages: payload.availableLanguages,
      };

    default:
      return state;
  }
};

export default languageReducer;
