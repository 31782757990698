export const submit = (user) => ({ type: 'SUBMIT', user });
export const submitPreferencesForm = (user, isConfirmed) => ({
  type: 'SUBMIT',
  user,
  isConfirmed,
});
export const ambiguous = () => ({ type: 'AMBIGUOUS' });
export const token = () => ({ type: 'TOKEN' });
export const login = () => ({ type: 'LOGIN' });
export const updateUser = (user) => ({ type: 'UPDATE_USER', user });
export const logout = () => ({ type: 'LOGOUT' });
export const revert = () => ({ type: 'REVERT' });
export const closeAll = () => ({ type: 'CLOSE_ALL' });
export const error = () => ({ type: 'ERROR' });
export const toggleVoterProfile = () => ({ type: 'TOGGLE_VOTER_PROFILE' });
export const toggleLanguageMenu = () => ({ type: 'TOGGLE_LANGUAGE_MENU' });
export const closeModal = () => ({ type: 'CLOSE_MODAL' });
export const success = () => ({ type: 'SUCCESS' });
export const updateGlobalNotifications = (
  messages,
  type = 'notice',
  icon = 'notice',
) => ({
  type: 'UPDATE_GLOBAL_ALERTS',
  alerts: {
    error: {
      type,
      icon,
      messages,
    },
  },
});
export const updateGlobalError = (message) => ({
  type: 'UPDATE_GLOBAL_ALERTS',
  alerts: {
    error: {
      type: 'error',
      icon: 'error',
      messages: [
        {
          text: message,
          important: true,
        },
      ],
    },
  },
});
export const showGlobalError = (message) => ({
  type: 'SHOW_GLOBAL_ALERT',
  alertType: 'error',
  message,
});
export const showGlobalNotification = (message) => ({
  type: 'SHOW_GLOBAL_ALERT',
  alertType: 'notice',
  message,
});
export const showGlobalSuccess = (message) => ({
  type: 'SHOW_GLOBAL_ALERT',
  alertType: 'success',
  message,
});
export const setCurrentLanguage = (currentLanguage) => ({
  type: 'SET_CURRENT_LANGUAGE',
  payload: { currentLanguage },
});
export const setAvailableLanguages = (availableLanguages) => ({
  type: 'SET_AVAILABLE_LANGUAGES',
  payload: { availableLanguages },
});
export const criticalDataReady = () => ({ type: 'CRITICAL_DATA_READY' });
export const fetchUser = (user) => ({
  type: 'FETCH_USER',
  user,
});
export const fetchDeliveryTypes = (deliveryTypes) => ({
  type: 'FETCH_DELIVERY_TYPES',
  deliveryTypes,
});
export const ballotStatusProgress = (ballots) => ({
  type: 'BALLOT_STATUS_PROGRESS',
  ballots,
});
export const brandingSuccess = (branding) => ({
  type: 'BRANDING_SUCCESS',
  branding,
});
export const openModal = (modalToOpen) => ({
  type: 'OPEN_MODAL',
  modal: {
    currentlyOpenModal: modalToOpen,
  },
});
export const noCurrentBallots = (payload) => ({
  type: 'NO_CURRENT_BALLOTS',
  payload,
});
export const translationsLoading = () => ({
  type: 'TRANSLATIONS_LOADING',
});
export const translationsSuccess = (translations) => ({
  type: 'TRANSLATIONS_SUCCESS',
  translations,
});
export const setLoginErrors = (fields) => ({
  type: 'SET_LOGIN_ERRORS',
  fields,
});
export const appendLoginError = (fields) => ({
  type: 'APPEND_LOGIN_ERROR',
  fields,
});
export const clearLoginError = (field) => ({
  type: 'CLEAR_LOGIN_ERROR',
  field,
});
export const setLaunchDarklyClientId = (clientId) => ({
  type: 'SET_LAUNCHDARKLY_CLIENT_ID',
  clientId,
});
export const setUpdateUserError = (validationMessages) => ({
  type: 'UPDATE_USER_ERROR',
  validationMessages,
});
export const sendAnalyticsEvent = (eventData) => ({
  type: 'SEND_GA_EVENT',
  eventData,
});
export const sendAnalyticsPageView = (page) => ({
  type: 'SEND_GA_PAGE_VISIT',
  page,
});
export const sendAnalyticsModalView = (page) => ({
  type: 'SEND_GA_MODAL_VIEW',
  page,
});
export const setUserIdleTimer = (idle_timer) => ({
  type: 'SET_USER_IDLE_TIMER',
  idle_timer,
});
export const setLDFlags = (flags) => ({ type: 'SET_LD_FLAGS', flags });
