import React from 'react';
import PreferencesTitleSection from '../icons/PreferencesTitleSection';
import OptSwitch from '../OptSwitch';
import FieldNote from '../../containers/preferences/PreferencesFieldNoteContainer';
import StartTimeInputField from '../../containers/inputs/StartTimeInputFieldContainer';
import EndTimeInputField from '../../containers/inputs/EndTimeInputFieldContainer';
import withTranslate from '../withTranslate';

export interface LimitHoursFieldProps {
  values: {
    limitHoursOptIn: boolean;
    limitStart: string;
    limitEnd: string;
  };
  setValues: (o: object) => void;
  _t: (str: string) => string[];
  user: {
    limitStart: string;
    limitEnd: string;
  };
  formatter: (time: string) => string;
}

export const LimitHoursField = ({
  values,
  setValues,
  _t,
  user,
  formatter,
}: LimitHoursFieldProps) => {
  const screenReaderPhrase = values.limitHoursOptIn
    ? _t('You have chosen to limit your notification hours.')
    : _t('You have chosen to not limit your notification hours.');

  const contactAlways = !values.limitHoursOptIn;

  return (
    <section className="preference-section limitHours-section">
      <div className="preference-section-header">
        <PreferencesTitleSection
          type={'time'}
          title={_t('Limit notification hours')}
        />
        {contactAlways && (
          <OptSwitch
            _t={_t}
            id={'Limit notification hours'}
            optedIn={values.limitHoursOptIn}
            screenReaderPhrase={screenReaderPhrase.join('')}
            onClick={() => {
              setValues({
                ...values,
                limitHoursOptIn: contactAlways,
                limitStart: !contactAlways ? '08:00' : user.limitStart,
                limitEnd: !contactAlways ? '20:00' : user.limitEnd,
                // default times set when limitHoursOptIn is true (not false)
                // due to setValues being async
                // pending https://github.com/jaredpalmer/formik/issues/529
              });
            }}
          />
        )}
      </div>
      <div className="time-input-section">
        <label
          id={'limitStartLabel'}
          className="screen-reader"
          htmlFor={'limitStart'}
        >
          {_t('Limit notification hours from')}
        </label>
        <StartTimeInputField
          exclude={values.limitEnd}
          include={user.limitStart}
          onChange={(e: any) => {
            setValues({
              ...values,
              limitStart: e.target.value,
              limitHoursOptIn: true,
            });
          }}
          value={values.limitStart}
          id={'limitStart'}
        />
        <span className="timeTo">{_t('TO')}</span>
        <label
          id={'limitEndLabel'}
          className="screen-reader"
          htmlFor={'limitEnd'}
        >
          {_t('Limit notification hours until')}
        </label>
        <EndTimeInputField
          exclude={values.limitStart}
          include={user.limitEnd}
          onChange={(e: any) => {
            setValues({
              ...values,
              limitEnd: e.target.value,
              limitHoursOptIn: true,
            });
          }}
          value={values.limitEnd}
          id={'limitEnd'}
        />
      </div>
      <div aria-live="polite" aria-atomic="true" aria-relevant="all">
        <FieldNote
          field={'limitHours'}
          optStatus={values.limitHoursOptIn}
          replacements={{
            early: formatter(values.limitStart),
            late: formatter(values.limitEnd),
          }}
        />
      </div>
    </section>
  );
};

export default withTranslate(LimitHoursField);
