import { connect } from 'react-redux';
import { revert } from 'actionCreators';
import TokenForm from 'components/forms/TokenForm';

const mapStateToProps = (state) => ({
  machine: state.machine,
});

const mapDispatchToProps = (dispatch) => {
  return {
    escHandler: (e) => {
      if (e.keyCode === 27) {
        return dispatch(revert());
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenForm);
