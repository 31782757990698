import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';

// *svg-cleanup*
export const ModalTitle = ({ title, closeModal, _t }) => (
  <div className="modal-title">
    <p>{_t(title)}</p>
    <button
      onClick={closeModal}
      className="close-modal"
      aria-label={_t(`Close ${title} window`)}
    >
      X
    </button>
  </div>
);

export default withTranslate(ModalTitle);

ModalTitle.propTypes = {
  _t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
};
