import React from 'react';
import active from 'img/optin_indicator_active.png';
import inactive from 'img/optin_indicator_inactive.png';
import error from 'img/optin_indicator_error.png';
import Email from 'components/icons/Email';
import Sms from 'components/icons/Sms';
import Voice from 'components/icons/Voice';
import Language from 'components/icons/Language';
import Time from 'components/icons/Time';

//TODO: This has a sister component: NewBallotInfoIcon. These components can be abstracted when the time is right..

interface PreferencesTitleSectionProps {
  type: string;
  title: string[];
}

const PreferencesTitleSection = ({
  type,
  title,
}: PreferencesTitleSectionProps) => {
  const icon: JSX.Element = {
    active: (
      <img src={active} alt={'Active icon'} className="preference-icon" />
    ),
    inactive: (
      <img src={inactive} alt={'Inactive icon'} className="preference-icon" />
    ),
    error: <img src={error} alt={'Error icon'} className="preference-icon" />,
    email: <Email />,
    sms: <Sms />,
    voice: <Voice />,
    time: <Time />,
    language: <Language />,
  }[type] || (
    <img src={undefined} alt={'Preferences icon'} className="preference-icon" />
  );

  return (
    <div
      className={`preference-title-section ${type}`}
      data-testid="preference-title-section"
    >
      {icon}
      <div className="preference-title">{title}</div>
    </div>
  );
};

export default PreferencesTitleSection;
