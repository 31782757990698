import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';

export const PrivacyDisclosure = ({ _t }) => (
  <div className="privacy-disclosure content">
    <p>
      {_t(
        '{{ballotTrax}} fully respects its users right to privacy. We are just as ' +
          'concerned about your privacy and the use of your personal information. ' +
          'We assure you that any and all information you provide us with will ' +
          'remain confidential. {{ballotTrax}} is dedicated to protecting your privacy ' +
          'and ensuring confidence in our secure ballot locator and notification ' +
          'system.',
        { ballotTrax: _t('BallotTrax') },
      )}
    </p>
    <p>
      {_t(
        'By signing up with {{ballotTrax}}, you agree to opt-in to receive automatic ' +
          'mail ballot locating and notification services and you give your county ' +
          'permission to use your contact information to communicate election ' +
          'related information to you.',
        { ballotTrax: _t('BallotTrax') },
      )}
    </p>
    <p>
      {_t(
        'Your personal information will not be sold or in any way divulged to any ' +
          '3rd parties. You may opt-out at any time.',
      )}
    </p>
  </div>
);

export default withTranslate(PrivacyDisclosure);

PrivacyDisclosure.propTypes = {
  _t: PropTypes.func.isRequired,
};
