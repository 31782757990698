import React from 'react';
import StatusGraphic from 'components/currentBallots/StatusGraphic';
import CurrentBallotStatus from 'components/currentBallots/CurrentBallotStatus';
import CurrentBallotDetails from 'components/currentBallots/CurrentBallotDetails';
import withTranslate from 'components/withTranslate';
import PropTypes from 'prop-types';

export const CurrentBallot = ({ _t, currentBallots }) => {
  function getDisplayText(statusSummary) {
    if (statusSummary.status === 'complete') {
      return _t('COMPLETED');
    }
    if (statusSummary.status === 'halted') {
      return statusSummary.current_status.display_name;
    }
    return `${statusSummary.current_step} / ${statusSummary.total_steps}`;
  }

  function getGraphicType(statusSummary) {
    if (statusSummary.current_status.status === 'reprinted') {
      return 'caution-graphic';
    }
    if (statusSummary.status === 'halted') {
      return 'halted-graphic';
    }
    return 'progress';
  }

  function getExpectedDeliveryDate(currentStatus) {
    if (currentStatus.outbound_expected_delivery_date) {
      return currentStatus.outbound_expected_delivery_date;
    }
    if (currentStatus.inbound_expected_delivery_date) {
      return currentStatus.inbound_expected_delivery_date;
    }
    return null;
  }

  return (
    <div data-testid={'current-ballots'}>
      {currentBallots.map((ballot) => {
        return (
          <section
            key={ballot.ballot_id}
            className="current-ballot dashboard-card"
          >
            <StatusGraphic
              _t={_t}
              currentStep={ballot.status_summary.current_step}
              displayText={getDisplayText(ballot.status_summary)}
              graphicType={getGraphicType(ballot.status_summary)}
              totalSteps={ballot.status_summary.total_steps}
              isComplete={ballot.status_summary.status === 'complete'}
            />
            <CurrentBallotStatus
              displayName={ballot.status_summary.current_status.display_name}
              displayDescription={
                ballot.status_summary.current_status.display_description
              }
              dateTime={ballot.status_summary.current_status.datetime}
              electionName={ballot.election.election_name}
              expectedDeliveryDate={getExpectedDeliveryDate(
                ballot.status_summary.current_status,
              )}
            />
            <CurrentBallotDetails
              _t={_t}
              statusHistory={ballot.status_history}
              currentStep={ballot.status_summary.current_step}
              status={ballot.status_summary.status}
            />
          </section>
        );
      })}
    </div>
  );
};

CurrentBallot.propTypes = {
  _t: PropTypes.func.isRequired,
  currentBallots: PropTypes.array.isRequired,
};

export default withTranslate(CurrentBallot);
