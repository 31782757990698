import React from 'react';
import { SvgProps } from '../../interfaces/svg';

const ArrowRight = ({
  width = '24',
  height = '24',
  color = '#75797E',
  className = 'arrow-right',
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    className={className}
  >
    <g>
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
    <g>
      <path
        fill={color}
        d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"
      />
    </g>
  </svg>
);

export default ArrowRight;
