import replaceUrlDomainWithBrowserDomain from '../util/replaceUrlDomainWithBrowserDomain';
import { getPaginationLinks } from './handlePagination';

/*
 * Replace domains returned from HAL links with the current browser domain
 */
const processHalLinks = async (res: any) => {
  // Verify response is valid and has HAL links (_links)
  if (res && typeof res === 'object' && res._links) {
    // Iterate over _links property to access 'first', 'last', 'next', 'prev', etc
    Object.keys(res._links).forEach((key) => {
      // Replace link.href host returned from server with current browser host
      try {
        res._links[key].href = replaceUrlDomainWithBrowserDomain(
          res._links[key].href,
        );
      } catch (e) {}
    });

    // Add a paginationLinks property to the response.
    // Useful when using the Pagination component.
    res.paginationLinks = getPaginationLinks(res);
  }

  return res;
};

export default processHalLinks;
