import * as Yup from 'yup';
import {
  getChangedFields,
  filterPhoneFields,
  requiredFieldValidator,
} from 'models/UserPreferenceModel';

const schema = Yup.object().shape({
  email: Yup.string().trim().email('Invalid email. (Example: test@sample.com)'),
  sms: Yup.string().matches(/^[a-z0-9]{10}$/i, {
    message: 'Invalid phone number. (Example: (303) 333-3333)',
    excludeEmptyString: true,
  }),
  voice: Yup.string().matches(/^[a-z0-9]{10}$/i, {
    message: 'Invalid phone number. (Example: (303) 333-3333)',
    excludeEmptyString: true,
  }),
  emailOptIn: Yup.boolean(),
  smsOptIn: Yup.boolean(),
  voiceOptIn: Yup.boolean(),
  isValidEmail: Yup.boolean(),
  languagePreference: Yup.string(),
  limitEnd: Yup.string().matches(/^\d{2}:\d{2}$/),
  limitStart: Yup.string().matches(/^\d{2}:\d{2}$/),
});

const validatePreferences = (newValues, oldValues) => {
  let dirtyFields = getChangedFields(newValues, oldValues);
  dirtyFields = filterPhoneFields(dirtyFields);
  // This now has keys mapped to an array of error messages
  const requiredFieldErrors = requiredFieldValidator(newValues);

  const formHasDirtyFields = Object.keys(dirtyFields).length !== 0;

  if (formHasDirtyFields) {
    try {
      schema.validateSync({ ...dirtyFields }, { abortEarly: false });
    } catch (error) {
      const changedFieldErrors = error.inner
        .map((validationError) => [
          validationError.path,
          validationError.message,
        ])
        // Gather errors with same key into an array
        .reduce((prev, curr) => {
          const [path, err] = curr;
          const prevErrors = prev[path] || [];
          return Object.assign({}, prev, { [path]: [...prevErrors, err] });
        }, {});

      return { ...changedFieldErrors, ...requiredFieldErrors };
    }
  }
  return requiredFieldErrors;
};

export default validatePreferences;
