import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';

export const LoginInformation = ({ _t, accountId }) => {
  return (
    <div className="login-information">
      <div className="get-started">{_t('Get Started')}</div>
      <div
        className="branding-blurb"
        dangerouslySetInnerHTML={{
          __html: _t('NEW_VOTER_LOGIN_INTRO', {
            ballotTrax: _t('BallotTrax'),
          }).join(''),
        }}
      ></div>

      <div className="registration-notice">
        <p className={'update-notice'}>
          {_t(
            'Note: This system does not change or update your voter registration.',
          )}
        </p>
        <p
          className="faq-link"
          dangerouslySetInnerHTML={{
            __html: _t('BALLOTTRAX_FAQ_LINK').join(''),
          }}
        />
        {accountId === -1 || accountId === undefined ? (
          <p className="wmb-info">
            {_t(
              'Please visit {{wmbLink}} to see if {{ballotTrax}} is available where you live.',
              {
                wmbLink: (
                  <a
                    key="wmbLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wheresmyballot.com/"
                  >
                    wheresmyballot.com
                  </a>
                ),
                ballotTrax: _t('BallotTrax'),
              },
            )}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default withTranslate(LoginInformation);

LoginInformation.propTypes = {
  _t: PropTypes.func.isRequired,
  accountId: PropTypes.number,
};
