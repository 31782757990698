import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import EmailLink from 'components/links/EmailLink';
import TelephoneLink from 'components/links/TelephoneLink';
import Icon from 'components/icons/Icon';

export const SupportNotice = ({ _t, county, phone, email, url }) => {
  const r = Math.floor(Math.random() * 1000000000);

  // This is a hack to let Boulder use a second email address in this area
  const supportEmail = _t('SUPPORT_EMAIL', { disableWarning: true }).join('');

  let supportReplacement = null;
  if (supportEmail.length !== 0) {
    supportReplacement = (
      <EmailLink key={'support-email-link' + r} email={supportEmail} />
    );
  }
  let urlLink = null;
  if (url !== null) {
    urlLink = <a href={url}>{url}</a>;
  }

  // SUPPORT NOTICE is a special HTML capable replacement for the text of this component. Since there are
  // multiple replacements, most of which are JSX themselves, we get into a weird situation when replacing
  // this text with a text override if we want to support HTML. If we are doing just text with the same
  // replacements, it should be fine. Otherwise, please define a custom "SUPPORT NOTICE" text override on the
  // account and it will display that instead of the original content.
  const specialText = _t('SUPPORT NOTICE', { disableWarning: true }).join();

  return (
    <div className="support-notice">
      <Icon type={'information'} />
      {specialText === 'SUPPORT NOTICE' ? (
        <p>
          {_t(
            `If you have any problems using {{ballotTrax}}, or with the information provided, please contact {{county}} by phone at {{telephoneLink}} or by email at {{emailLink}}`,
            {
              ballotTrax: _t('BallotTrax'),
              county,
              telephoneLink: (
                <TelephoneLink key={'phone-link' + r} phone={phone} />
              ),
              emailLink: <EmailLink key={'email-link' + r} email={email} />,
              supportEmail: supportReplacement,
              countyWebsite: urlLink,
            },
          )}
        </p>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: specialText }} />
      )}
    </div>
  );
};

export default withTranslate(SupportNotice);

SupportNotice.propTypes = {
  _t: PropTypes.func.isRequired,
  county: PropTypes.string.isRequired,
  phone: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.string,
};
