import { GenericApiError, NotFound, BadRequest } from './ApiErrors';

const handleJsonErrors = ({
  body,
  isError,
}: {
  body: any;
  isError: boolean;
}) => {
  if (!isError) {
    return body;
  }

  const errorMessage = body.title
    ? `${body.status}: ${body.title} - ${body.detail}`
    : `${body.status}: ${body.detail}`;

  switch (body.status) {
    case 400:
      throw new BadRequest(body.detail);
    case 404:
      throw new NotFound(errorMessage);
    default:
      throw new GenericApiError(`Application Error: ${errorMessage}`);
  }
};

export default handleJsonErrors;
