import React from 'react';
import formatPhoneNumber from 'util/phone/formatPhoneNumber';
import PropTypes from 'prop-types';

const TelephoneLink = ({ phone }) => {
  const phoneLink = phone || '(877) 651-7036';

  return (
    <a href={`tel:${phoneLink}`} className="phone-link">
      {formatPhoneNumber(phoneLink)}
    </a>
  );
};

export default TelephoneLink;

TelephoneLink.propTypes = {
  phone: PropTypes.string,
};
