import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import { connect } from 'react-redux';

export const BallotSummary = ({ statusText, date, branding, _t }) => {
  return (
    <div className="ballot-summary">
      <div className="ballot-summary-status-text">{statusText}</div>
      {date && branding.is_ballot_status_dates_visible && (
        <div className="ballot-summary-date">{date}</div>
      )}
    </div>
  );
};

BallotSummary.propTypes = {
  statusText: PropTypes.string.isRequired,
  date: PropTypes.string,
  branding: PropTypes.object.isRequired,
  _t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ branding }) => ({
  branding: branding,
});

export default connect(mapStateToProps, null)(withTranslate(BallotSummary));
