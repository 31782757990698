const replaceUrlDomainWithBrowserDomain = (url: string) => {
  try {
    const origin = window.location.origin;
    const preparedUrl = new URL(url, origin);
    preparedUrl.host = window.location.host;
    preparedUrl.protocol = window.location.protocol;
    return preparedUrl.toString();
  } catch (e) {
    return url;
  }
};

export default replaceUrlDomainWithBrowserDomain;
