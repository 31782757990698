import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';

const PastBallot = ({ name, status, displayStatus }) => {
  const statusIconType =
    {
      accepted: 'accepted',
      'in-person': 'accepted',
      rejected: 'rejected',
      undeliverable: 'rejected',
    }[status] || 'incomplete';

  return (
    <div data-testid="past-ballot" className="past-ballot">
      <div className="details">
        <p className="name">{name}</p>
      </div>
      <div className={`status ${statusIconType}`}>
        <p>{displayStatus}</p>
        <Icon
          className={`past-ballot-${statusIconType}`}
          type={statusIconType}
        />
      </div>
    </div>
  );
};

export default PastBallot;

PastBallot.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  displayStatus: PropTypes.string.isRequired,
};
