import React from 'react';
import { Field } from 'formik';
import PreferencesTitleSection from '../icons/PreferencesTitleSection';
import OptSwitch from '../OptSwitch';
import FieldNote from '../../containers/preferences/PreferencesFieldNoteContainer';
import withTranslate from '../withTranslate';
import ErrorMessageList from '../notifications/ErrorMessageList';

export interface VoiceFieldProps {
  values: {
    voiceOptIn: boolean;
  };
  setValues: (o: object) => void;
  _t: (str: string) => string[];
  errors: {
    voice?: string[];
  };
  touched: {
    voice: boolean;
  };
}

export const VoiceField = ({
  values,
  setValues,
  _t,
  errors,
  touched,
}: VoiceFieldProps) => {
  return (
    <section className="preference-section voice-section">
      <div className="preference-section-header">
        <PreferencesTitleSection type={'voice'} title={_t('Opt-In Voice')} />
        <OptSwitch
          _t={_t}
          id={'Voice'}
          optedIn={values.voiceOptIn}
          onClick={() => {
            setValues({
              ...values,
              voiceOptIn: !values.voiceOptIn,
            });
          }}
        />
      </div>

      <div className="input-section">
        <label htmlFor={'voice'} className="preference-label">
          {_t('Phone Number (US Number Only)')}
        </label>
        <div>
          <Field
            className="preference-input"
            id={'voice'}
            name={'voice'}
            placeholder={_t('Example: (303) 333-3333')}
            onChange={(e: any) => {
              setValues({
                ...values,
                voice: e.target.value,
                voiceOptIn: true,
              });
            }}
            aria-describedby={'voice-error-list'}
          />
          <button
            className="clear-field"
            type="button"
            onClick={() =>
              setValues({ ...values, voice: '', voiceOptIn: false })
            }
          >
            &times;
            <div className="screen-reader">
              {_t('Clear voice message field contents')}
            </div>
          </button>
        </div>
      </div>
      <div aria-live="polite" aria-atomic="true" aria-relevant="all">
        {touched.voice && (
          <ErrorMessageList
            errors={errors.voice}
            field={'voice'}
            limit={3}
            id={'voice-error-list'}
          />
        )}
        {!(errors.voice && touched.voice) && (
          <FieldNote
            field={'voice'}
            optStatus={values.voiceOptIn}
            replacements={{}}
          />
        )}
      </div>
    </section>
  );
};

export default withTranslate(VoiceField);
