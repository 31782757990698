import * as Yup from 'yup';

const LoginFormValidationSchema = Yup.object().shape({
  firstname: Yup.string().max(60, 'First name is too long.'),
  lastname: Yup.string()
    .max(60, 'Last name is too long.')
    .required('Last name is required.'),
  recaptcha: Yup.string().required('Please complete the CAPTCHA'),
  zipcode: Yup.string()
    .matches(/^\d{5}$/, 'Zip code must be 5 digits.')
    .required('Zip code is required.'),
  birthdate: Yup.string().required('Date of birth is required.'),
});

export default LoginFormValidationSchema;
