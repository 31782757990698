export const preferenceErrorMessages = {
  email: {
    required:
      "You've chosen to be contacted via Email. Please enter an email address or click X to opt out.",
    invalidEmail:
      'Provided email address cannot be used to receive emails because it is invalid, has unsubscribed, or marked the message as spam. Please change email address or uncheck opt-in.',
  },
  sms: {
    required:
      "You've chosen to be contacted via Text. Please enter a phone number or click X to opt out.",
  },
  voice: {
    required:
      "You've chosen to be contacted via Voice. Please enter a phone number or click X to opt out.",
  },
};
