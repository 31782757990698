import { Action, TranslationsAction } from '../interfaces/action';
import initialTranslator, { Translator } from './initialTranslator';
import getTranslator from '../util/translator/translator';

const translationsReducer = (
  state: Translator = initialTranslator,
  action: Action,
) => {
  if (action.type === 'TRANSLATIONS_SUCCESS') {
    const { translations } = action as TranslationsAction;
    return {
      _t: getTranslator(translations, true),
    };
  }

  return state;
};

export default translationsReducer;
