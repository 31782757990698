import React from 'react';
import PropTypes from 'prop-types';
import ProgressGraphic from 'components/ProgressGraphic';
import HaltedGraphic from 'components/HaltedGraphic';

export const StatusGraphic = ({ graphicType, ...props }) =>
  graphicType === 'progress' ? (
    <ProgressGraphic {...props} />
  ) : (
    <HaltedGraphic graphicType={graphicType} displayText={props.displayText} />
  );

export default StatusGraphic;

StatusGraphic.propTypes = {
  graphicType: PropTypes.string.isRequired,
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  totalSteps: PropTypes.number,
  currentStep: PropTypes.number,
};
