const getLocaleFromLanguage = (language, defaultLocale = 'en_US') => {
  const locales = {
    arabic: 'ar_IQ',
    bengali: 'bn_BN',
    cantonese: 'zh_HK',
    chinese: 'zh_CN',
    creole: 'ht_HT',
    english: 'en_US',
    french: 'fr_FR',
    german: 'de_DE',
    hindi: 'hi_IN',
    ilocano: 'ilo',
    japanese: 'ja_JP',
    hawaiian: 'haw_US',
    khmer: 'km_KH',
    korean: 'ko_KR',
    russian: 'ru_RU',
    somali: 'so_SO',
    spanish: 'es_ES',
    tagalog: 'tl_PH',
    thai: 'th_TH',
    ukrainian: 'uk_UA',
    vietnamese: 'vi_VN',
  };
  return locales[language] || defaultLocale;
};

export default getLocaleFromLanguage;
