import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import DropdownArrow from 'img/arrow_dropdown_prefs.png';

export const ContactLanguageDropdown = ({
  onChange,
  availableLanguages,
  currentLanguage,
  _t,
}) => (
  <>
    <label className="screen-reader" htmlFor="contact-language-dropdown">
      {_t('Preferred Language')}
    </label>
    <select
      name="languagePreference"
      className="contact-language-dropdown"
      onChange={onChange}
      value={currentLanguage}
      id="contact-language-dropdown"
      style={{ background: `url(${DropdownArrow}) no-repeat right` }}
    >
      {availableLanguages.map(({ language, abbreviation }, i) => (
        <option value={language} key={i}>
          {abbreviation} - {_t(language)}
        </option>
      ))}
    </select>
  </>
);

ContactLanguageDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  _t: PropTypes.func.isRequired,
};

export default withTranslate(ContactLanguageDropdown);
