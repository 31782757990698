import React from 'react';
import PropTypes from 'prop-types';
import BallotStep from 'components/BallotStep';

const BallotPath = ({ statusHistory }) => (
  <div className="ballot-path" data-testid="ballot-path">
    {statusHistory.map((stepDetails, i) => (
      <BallotStep stepDetails={stepDetails} key={i} />
    ))}
  </div>
);

export default BallotPath;

BallotPath.propTypes = {
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      datetime: PropTypes.string,
      display_description: PropTypes.string.isRequired,
      display_name: PropTypes.string.isRequired,
      is_final: PropTypes.bool.isRequired,
      is_standard_flow: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
