import { connect } from 'react-redux';
import { closeAll } from 'actionCreators';
import GlobalAlerts from 'components/notifications/GlobalAlerts';

const mapStateToProps = ({ alertsNew, branding }) => ({
  alerts: alertsNew,
  accountId: branding.account_id,
});

const mapDispatchToProps = (dispatch) => ({
  closeAll: () => dispatch(closeAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlerts);
