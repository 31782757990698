import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StateMatcher from '../../containers/StateMatcherContainer';
import Loading from '../../components/shared/Loading';
import ReactGA from 'react-ga4';
import BallotLogo from '../BallotLogo';

export const SmsPolicyPage = ({ _t }) => {
  useEffect(() => {
    ReactGA.set({ page: '/voter/sms-policy' });
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <StateMatcher matches={'translations.loading'}>
        <Loading className={'privacy-loading'} />
      </StateMatcher>
      <StateMatcher matches={'translations.complete'}>
        <div className="sms-policy-page policy-page">
          <nav className="nav-bar">
            <Link to="/">
              <BallotLogo />
            </Link>
          </nav>

          <main className="page-layout">
            <h1>{_t('SMS Terms')}</h1>
            <ol>
              <li className={'sms-policy-li'}>
                {_t(
                  '{{ballotTrax}} keeps you, the voter, informed about the status of your mail-in ballot. The system can send you alerts when your ballot is printed, sent to you, undeliverable, returned from you, rejected, accepted, or voted in person. Which messages you receive may vary based on your state or local elections office and the choices they’ve made about which messages to send. You may also receive reminders to vote if your ballot has not been returned during an election.',
                  { ballotTrax: _t('BallotTrax') },
                )}
              </li>
              <li className={'sms-policy-li'}>
                {_t(
                  'You can cancel the SMS service at any time by texting "STOP" to the short code 41575. After you send the SMS message "STOP" to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, simply sign up as you did the first time and we will start sending SMS messages to you again.',
                )}
              </li>
              <li className={'sms-policy-li'}>
                {_t(
                  'If you are experiencing issues with the messaging program you can reply with the keyword "HELP" for more assistance, or email {{help@ballottrax.com}}.',
                  { 'help@ballottrax.com': 'help@ballottrax.com' },
                )}
              </li>
              <li className={'sms-policy-li'}>
                {_t(
                  'Carriers are not liable for delayed or undelivered messages.',
                )}
              </li>
              <li className={'sms-policy-li'}>
                {_t(
                  'As always, message and data rates may apply for any messages sent to you from us and to us from you. You will receive messages based on your ballot status changing, typically 2-5 messages per election. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.',
                )}
              </li>
              <li className={'sms-policy-li'}>
                {_t(
                  'If you have any questions regarding privacy, please read our',
                )}{' '}
                <a href="/voter/privacy"> {_t('Privacy Policy')} </a>
              </li>
            </ol>
          </main>

          <section className={'sms-button-section'}>
            <Link to="/">
              <button className="primary-button privacy-button">
                <span>
                  {_t('Visit {{ballotTrax}}', { ballotTrax: _t('BallotTrax') })}
                </span>
              </button>
            </Link>
          </section>

          <footer>
            <span className="copy-right">
              {_t('© {{date}} by i3logix, inc. All rights reserved.', {
                date: new Date().getFullYear(),
              })}
            </span>
          </footer>
        </div>
      </StateMatcher>
    </>
  );
};

export default SmsPolicyPage;

SmsPolicyPage.propTypes = {
  _t: PropTypes.func.isRequired,
};
