import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';

export const LogoutButton = ({ onClick, _t }) => (
  <button className="logout-button" onClick={onClick}>
    {_t('Log Out')}
  </button>
);

export default withTranslate(LogoutButton);

LogoutButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  _t: PropTypes.func.isRequired,
};
