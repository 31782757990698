import React from 'react';
import PropTypes from 'prop-types';
import StateMatcher from 'containers/StateMatcherContainer';
import VoterProfileBtn from 'containers/VoterProfileBtnContainer';
import Spacer from 'components/shared/Spacer';
import LanguageDropdown from '../containers/LanguageDropdownContainer';
import LogoutButton from 'containers/LogoutButtonContainer';

const NavBarButtons = ({ multipleLanguages }) => (
  <div className="nav-bar-buttons" data-testid="nav-bar-buttons">
    {multipleLanguages && <LanguageDropdown />}
    <StateMatcher matches={'app.loggedIn'}>
      {multipleLanguages && <Spacer />}
      <VoterProfileBtn />
      <Spacer className="logout-spacer" />
      <LogoutButton />
    </StateMatcher>
  </div>
);

export default NavBarButtons;

NavBarButtons.propTypes = {
  multipleLanguages: PropTypes.bool.isRequired,
};
