const initialState = {};

const loginFormInlineErrorReducer = (state, action) => {
  state = typeof state !== 'undefined' ? state : initialState;

  switch (action.type) {
    case 'SET_LOGIN_ERRORS':
      return action.fields;
    case 'APPEND_LOGIN_ERROR':
      return { ...state, ...action.fields };
    case 'CLEAR_LOGIN_ERROR':
      const newState = { ...state };
      delete newState[action.field];
      return newState;
    default:
      return state;
  }
};

export default loginFormInlineErrorReducer;
