import React from 'react';
import PropTypes from 'prop-types';
import BallotSummary from 'components/BallotSummary';
import BallotDetail from 'components/BallotDetail';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CurrentBallotStatus = ({
  displayName,
  dateTime,
  displayDescription,
  electionName,
  expectedDeliveryDate,
}) => {
  const { voterUiEstimatedDeliveryDate } = useFlags();

  const formatDate = (date) => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  };
  const printDateRange = (edd) => {
    let dateParts = edd.split('/');
    let deliveryDate = new Date(+dateParts[2], dateParts[0] - 1, +dateParts[1]);

    let deliveryDatePlus3Days = new Date(deliveryDate.getTime());
    deliveryDatePlus3Days.setDate(deliveryDate.getDate() + 2);

    return ` ${formatDate(deliveryDate)} - ${formatDate(
      deliveryDatePlus3Days,
    )}`;
  };

  return (
    <div className="current-ballot-status">
      <div className="election-name">{electionName}</div>
      <BallotSummary statusText={displayName} date={dateTime} />
      <BallotDetail text={displayDescription} />
      {voterUiEstimatedDeliveryDate && expectedDeliveryDate ? (
        <p className="expected-delivery-date">
          Expected Delivery Date: {printDateRange(expectedDeliveryDate)}
        </p>
      ) : null}
    </div>
  );
};

export default CurrentBallotStatus;

CurrentBallotStatus.propTypes = {
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  electionName: PropTypes.string.isRequired,
  expectedDeliveryDate: PropTypes.string,
};
