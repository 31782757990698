import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import Icon from 'components/icons/Icon';

const getAlertMessages = (alert, accountId, _t) => {
  return alert.messages.map((message, index) => {
    const { text, important } = message;

    let alertMessage = (
      <span
        key={index}
        className={important ? 'global-alert-label' : 'global-alert-message'}
        dangerouslySetInnerHTML={{ __html: _t(text, message).join('') }}
      />
    );

    if (
      (accountId === -1 || accountId === undefined) &&
      text ===
        "Your information doesn't match any voters in our system. Please double-check that what you've entered is correct and make sure that your first name is the same as your first name on your voter registration."
    ) {
      return (
        <Fragment key={'unbranded-invalid-alert'}>
          {alertMessage}
          <span className="global-alert-message">
            {_t(
              'Please visit {{wmbLink}} to see if {{ballotTrax}} is available where you live.',
              {
                wmbLink: (
                  <a
                    key={'wmb-link'}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wheresmyballot.com/"
                  >
                    wheresmyballot.com
                  </a>
                ),
                ballotTrax: _t('BallotTrax'),
              },
            )}
          </span>
        </Fragment>
      );
    }

    return alertMessage;
  });
};

export const GlobalAlert = ({ alert, closeAll, accountName, _t }) => {
  return (
    <div
      className={`global-alert ${alert.type}-alert`}
      data-testid={'global-alert'}
    >
      <Icon type={alert.icon} className="global-alert-icon" />

      <div className="global-alert-content" aria-live="polite">
        {getAlertMessages(alert, accountName, _t)}
      </div>

      <button
        className="close-global-alert-btn"
        aria-label="Close Alert"
        onClick={closeAll}
      >
        X
      </button>
    </div>
  );
};

export default withTranslate(GlobalAlert);

GlobalAlert.propTypes = {
  alert: PropTypes.object,
  closeAll: PropTypes.func.isRequired,
  accountId: PropTypes.number,
  _t: PropTypes.func.isRequired,
};
