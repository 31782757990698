import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ArrowDownOutline from '../../img/ArrowDownOutline';

interface Tab {
  title: string;
  content: any;
  id: number;
}

interface TabbedContainerProps {
  tabs: Tab[];
  className?: string;
}

const TabbedContainer = forwardRef(
  ({ tabs, className = '' }: TabbedContainerProps, ref) => {
    const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
    const [headerClosed, setHeaderClosed] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({
      selectTab(tabTitle: string) {
        const currTag = tabs.find((tab) => tab.title === tabTitle);
        if (currTag) {
          setActiveTab(currTag);
        }
      },
    }));

    const getHeader = (desktopTabs: Tab[]) => {
      return desktopTabs.map((tab) => {
        return (
          <header
            key={tab.title}
            className={`tab-card-header card-header  ${
              tab.id === activeTab.id ? 'active' : 'inactive'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            <span className="card-header-title">{tab.title}</span>
          </header>
        );
      });
    };

    const handleDetailClick = (tab: Tab) => {
      setHeaderClosed(true);
      setActiveTab(tab);
    };

    const getMobileHeader = (mobileTabs: Tab[]) => {
      const header = [];
      header.push(
        <header
          key={activeTab.title}
          className={`mobile-tab-card-header  active`}
          onClick={() => setHeaderClosed(!headerClosed)}
        >
          <span className="card-header-title">{activeTab.title}</span>
          <ArrowDownOutline color={'white'} title={'can sort column'} />
        </header>,
      );
      if (headerClosed) {
        return header;
      }
      mobileTabs.forEach((tab, index) => {
        header.push(
          <div
            key={index}
            className={`mobile-dropdown-item`}
            onClick={() => handleDetailClick(tab)}
          >
            <span className="card-header-title">{tab.title}</span>
          </div>,
        );
        return;
      });
      return header;
    };

    return (
      <div className={`tab-card card ${className}`}>
        <div className="head-container">
          <div id="mobile-header">{getMobileHeader(tabs)}</div>
          <div id="full-header">{getHeader(tabs)}</div>
        </div>
        <div className="body-container">
          {activeTab ? activeTab.content : null}
        </div>
      </div>
    );
  },
);

export default TabbedContainer;
