import { applyMiddleware, createStore } from 'redux';
import { createMiddleware } from 'util/redux/redux-xstate';
import { machine } from 'statechart';
import { actionMap } from 'actionMap';
import rootReducer from 'reducers/rootReducer';

const stateSanitizer = (state) => ({
  ...state,
  machine: {
    ...state.machine.value,
  },
});

const store = () => {
  const reduxStore = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__({
        stateSanitizer,
      }),
    applyMiddleware(createMiddleware(machine, actionMap)),
  );

  // make sure initial state actions are called
  machine.initialState.actions
    .map((key) => actionMap[key])
    .filter(Boolean)
    .forEach((fn) => fn(reduxStore.dispatch, machine.initialState));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('reducers/rootReducer', () => {
        reduxStore.replaceReducer(rootReducer);
      });
    }
  }
  return reduxStore;
};

export default store;
