import React, { useCallback, useEffect, useRef } from 'react';
import ElectionOverview from 'containers/ElectionOverviewContainer';
import Preferences from 'components/Preferences';
import SupportNotice from 'containers/SupportNoticeContainer';
import Footer from 'components/Footer';
import ReactGA from 'react-ga4';
import TabbedContainer from 'components/tab/TabbedContainer';
import PropTypes from 'prop-types';
import withTranslate from 'components/withTranslate';
import { connect } from 'react-redux';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import ArchivedBallots from '../archivedBallots/ArchivedBallots';

const DashboardPage = ({ _t, deliveryTypes, ballots, user }) => {
  const { tabbedBallots } = useFlags();

  const ldClient = useLDClient();

  useEffect(() => {
    if (!user.state) {
      return;
    }
    ldClient.identify({
      key: 'Voter-' + user.state + '-' + user.county,
      firstname: user.state,
      lastName: user.county,
      custom: {
        state: user.state,
        county: user.county,
      },
      anonymous: false,
    });
    //Not using the promise returned from identify (which returns all flags)
    //because we are using the useFlags hook.
  }, [ldClient, user.state, user.county]);

  const currentBallots = useRef();

  // Get the history state property { state: tab }
  const selectTab = useCallback((e) => {
    const state =
      e.arguments.length && e.arguments[0].state ? e.arguments[0].state : null;

    if (state === null) {
      return;
    }

    // Go through each tabbedPanels on the dashboard and
    // try to set the tab matching the tab

    if (currentBallots.current) {
      currentBallots.current.selectTab(state);
    }
  }, []);

  // Listen for the 'pushState' effect declared in MobileNav
  // which sets the history in the form of
  // window.history.pushState({ state: tab }, tab, '');
  useEffect(() => {
    ReactGA.set({ page: '/voter/dashboard' });
    ReactGA.send('pageview');
    window.addEventListener('pushState', selectTab);

    return () => {
      window.removeEventListener('pushState', selectTab);
    };
  }, [selectTab]);

  return (
    <main
      className="dashboard-grid"
      id="dashboard-main-content"
      data-testid="dashboard-page"
    >
      <section className="dashboard-grid-ballots grid-item">
        {tabbedBallots ? (
          <TabbedContainer
            ref={currentBallots}
            className={'dashboard-ballot-status-tab'}
            tabs={[
              {
                title: _t('Current Mail Ballot Status').join(''),
                content: <ElectionOverview showTitle={false} />,
                id: 0,
              },
              {
                title: _t('Previous Mail Ballots').join(''),
                content: (
                  <ArchivedBallots
                    currentBallots={ballots.currentBallots.length > 0}
                    showTitle={false}
                  />
                ),
                id: 1,
              },
            ]}
          />
        ) : (
          <ElectionOverview showTitle={true} />
        )}
        {!tabbedBallots && (
          <ArchivedBallots currentBallots={ballots.currentBallots.length > 0} />
        )}
      </section>
      <section className="dashboard-grid-prefs grid-item">
        <section className={'dashboard-grid-help'}>
          <SupportNotice />
        </section>
        {deliveryTypes.length !== 0 && (
          <Preferences
            rootClassName={''}
            shouldRedirect={false}
            onDashboard={true}
          />
        )}
      </section>
      <section className="dashboard-grid-footer grid-item">
        <Footer />
      </section>
    </main>
  );
};

const mapStateToProps = ({ deliveryTypes, ballots, user }) => {
  return { deliveryTypes, ballots, user };
};

export default connect(mapStateToProps)(withTranslate(DashboardPage));

DashboardPage.propTypes = {
  _t: PropTypes.func.isRequired,
  deliveryTypes: PropTypes.array.isRequired,
  ballots: PropTypes.object,
};
