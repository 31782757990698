import React, { Fragment } from 'react';
import BaseModal from 'react-modal';
import ModalTitle from 'components/modal/content/ModalTitle';
import PropTypes from 'prop-types';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
BaseModal.setAppElement(document.getElementById('root'));

const Modal = ({ isOpen, title, Component, closeModal }) => (
  <BaseModal
    isOpen={isOpen}
    className="modal theme-orig"
    onRequestClose={closeModal}
    contentLabel={title}
    overlayClassName="overlay"
  >
    {isOpen && (
      <Fragment>
        <ModalTitle title={title} closeModal={closeModal} />
        <Component />
      </Fragment>
    )}
  </BaseModal>
);

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  Component: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
};
