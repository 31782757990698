import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ translations: { _t } }) => ({
  _t,
});

export default function withTranslate(ComposedComponent) {
  const Component = (props) => <ComposedComponent {...props} />;

  return connect(mapStateToProps)(Component);
}
