import { Action, BallotAction } from '../interfaces/action';
import { Ballot } from '../interfaces/ballot';
import { initialBallots } from './initialBallots';
import moment from 'moment/moment';

interface State {
  currentBallot?: Ballot;
  currentBallots: Ballot[];
}

export const formattedBallot = (ballot: any) => {
  const statusHistory = ballot.status_history.map((ballotStep: any) => {
    if (ballotStep.datetime) {
      ballotStep = {
        ...ballotStep,
        datetime: moment(ballotStep.datetime).format('L'),
      };
    }
    return ballotStep;
  });

  const currentStatusDate = ballot.status_summary.current_status.datetime;

  const currentStatus = {
    ...ballot.status_summary.current_status,
    datetime: currentStatusDate ? moment(currentStatusDate).format('L') : '',
  };

  if (ballot.status_summary.current_status.outbound_expected_delivery_date) {
    currentStatus.outbound_expected_delivery_date = moment(
      ballot.status_summary.current_status.outbound_expected_delivery_date,
    ).format('L');
  }

  if (ballot.status_summary.current_status.inbound_expected_delivery_date) {
    currentStatus.inbound_expected_delivery_date = moment(
      ballot.status_summary.current_status.inbound_expected_delivery_date,
    ).format('L');
  }

  const statusSummary = {
    ...ballot.status_summary,
    current_status: currentStatus,
  };

  return {
    ...ballot,
    status_history: statusHistory,
    status_summary: statusSummary,
  };
};

const ballotReducer = (
  state: Readonly<State> = initialBallots,
  action: Action,
): Readonly<State> => {
  if (action.type === 'BALLOT_STATUS_PROGRESS') {
    const { ballots } = action as BallotAction;
    const currentBallots = ballots.map((ballot) => formattedBallot(ballot));

    return {
      currentBallots,
    };
  }

  return state;
};

export default ballotReducer;
