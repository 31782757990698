const processJsonResponse = async (res: Response) => {
  let isError = false;
  if (res.status === 204) {
    return { body: null, isError };
  }
  const contentType = res.headers.get('content-type');
  if (!contentType || !contentType.match(/application\/.*json/)) {
    throw new TypeError('Non-Json Response');
  }
  if (contentType && contentType === 'application/problem+json') {
    isError = true;
  }

  return { body: await res.json(), isError };
};

export default processJsonResponse;
