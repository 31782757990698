export const abbreviations = {
  arabic: 'ar',
  bengali: 'bn',
  cantonese: 'zh',
  chinese: 'zh',
  creole: 'ht',
  english: 'en',
  french: 'fr',
  german: 'de',
  hindi: 'hi',
  hawaiian: 'haw',
  ilocano: 'ilo',
  japanese: 'ja',
  khmer: 'km',
  korean: 'ko',
  russian: 'ru',
  somali: 'so',
  spanish: 'es',
  tagalog: 'tl',
  thai: 'th',
  ukrainian: 'uk',
  vietnamese: 'vi',
};
