const cookies = {
  get: (key: string): string | null => {
    let value = fetchAllCookies().get(key) || null;
    // make a value that was never set behave the same way as a previously set and cleared value
    if (value === '') {
      value = null;
    }
    return value;
  },
  /**
   * Set a cookie value. If withBaseDomain is sent as true, then the cookie will be accessible for the domain it is
   * set on along with all subdomains
   *
   * @param key string
   * @param value string
   * @param withBaseDomain bool Default false
   */
  set: (key: string, value: string, withBaseDomain = false) => {
    let cookieValue = `${key}=${value};path=/`;
    if (withBaseDomain) {
      cookieValue += `;domain=${getBaseDomainName()}`;
    }
    document.cookie = cookieValue;
  },
  clear: (key: string) => {
    document.cookie = `${key}=;path=/`;
  },
};

// Not using this yet, but we will probably need it later.
function getBaseDomainName() {
  const host = window.location.hostname;
  const domainMap = [
    { suffix: 'ballottrax.net', domain: '.ballottrax.net' },
    { suffix: 'qa.i3ballot.net', domain: '.qa.i3ballot.net' },
    { suffix: 'i3ballot.net', domain: '.i3ballot.net' },
    { suffix: 'web.ballottrax', domain: '.web.ballottrax' },
  ];

  for (const check of domainMap) {
    if (host.endsWith(check.suffix)) {
      return check.domain;
    }
  }

  return `.${host}`;
}

/**
 * Turns a cookie string like 'cookie=chocolate; sandwich=ham;' into an Map:
 * {
 *   cookie: "chocolate",
 *   sandwich: "ham"
 * }
 */
const fetchAllCookies = () => {
  const decodedCookie = decodeURIComponent(document.cookie).split(';');
  const theCookies = new Map();

  for (const part of decodedCookie) {
    const [key, value] = part.split('=');
    theCookies.set(key.trim(), value);
  }

  return theCookies;
};

export default cookies;
