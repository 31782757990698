const jsonMap = {
  email: (email) => {
    return { email };
  },
  sms: (sms) => {
    return { phone_sms: sms };
  },
  voice: (voice) => {
    return { phone_voice: voice };
  },
  limitStart: (startTime) => ({
    email_do_not_contact_before: startTime,
    sms_do_not_contact_before: startTime,
    voice_do_not_contact_before: startTime,
  }),
  limitEnd: (endTime) => ({
    email_do_not_contact_after: endTime,
    sms_do_not_contact_after: endTime,
    voice_do_not_contact_after: endTime,
  }),
  emailOptIn: (emailOptIn) => {
    return { email_opt_in: emailOptIn };
  },
  smsOptIn: (smsOptIn) => {
    return { sms_opt_in: smsOptIn };
  },
  voiceOptIn: (voiceOptIn) => {
    return { voice_opt_in: voiceOptIn };
  },
  languagePreference: (languagePreference) => {
    return { language_preference: languagePreference };
  },
};

export default jsonMap;
