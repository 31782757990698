import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ language }) => ({
  currentLanguage: language.currentLanguage,
});

export default function withCurrentLanguage(ComposedComponent) {
  const Component = (props) => <ComposedComponent {...props} />;

  return connect(mapStateToProps)(Component);
}
