import React from 'react';
import { disabledAccessible } from './StandardIconColors';
import { SvgProps } from '../interfaces/svg';

const ArrowDownOutline = ({
  width = '24',
  height = '24',
  color = disabledAccessible,
  className = 'none',
  title,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    className={className}
  >
    {title ? <title>{title}</title> : null}
    <path fill={color} d="M7,10l5,5l5-5H7z M9.8,11h4.6L12,13.4L9.8,11z" />
    <path fill={'none'} d="M0,0h24v24H0V0z" />
  </svg>
);

export default ArrowDownOutline;
