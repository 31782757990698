import React from 'react';
const Outage = () => {
  return (
    <div style={{ color: 'black', border: '2px solid red', padding: '0.5em' }}>
      We apologize if you are having issues while registering for ballot status
      notifications. Due to the extremely high traffic volume we are
      experiencing, we are currently working on increasing our server capacity
      to fix these errors.
      <p>
        Please try again later today, if problems persist please reach out to{' '}
        <a href={'mailto:help@ballottrax.com'}>help@ballottrax.com</a>.
      </p>
    </div>
  );
};
export default Outage;
