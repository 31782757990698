import React, { ReactNode } from 'react';

interface RenderIfProps {
  condition: boolean;
  children: ReactNode | ReactNode[];
}
const RenderIf = ({ condition, children }: RenderIfProps) => {
  if (!!condition) {
    return <>{children}</>;
  }
  return null;
};
export default RenderIf;
