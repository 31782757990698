const initialState = {
  language: 'english',
  title: 'No Current Mail Ballot Status',
  description:
    'Currently, we do not have any available information on your mail ballot status. This may be because you’re a new user to the BallotTrax system or your county does not currently have an active election. Please check back soon for updated information or contact your county directly.',
};

interface NoBallotStatus {
  language: string;
  title: string;
  description: string;
}
interface NoBallotStatusAction {
  type: string;
  payload: NoBallotStatus;
}

const noBallotStatusReducer = (
  state: Readonly<NoBallotStatus> = initialState,
  action: NoBallotStatusAction,
): Readonly<NoBallotStatus> => {
  if (action.type === 'NO_CURRENT_BALLOTS') {
    return action.payload;
  }

  return state;
};

export default noBallotStatusReducer;
