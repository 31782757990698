import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'components/shared/RenderIf';

export default class StateMatcher extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <RenderIf
        condition={this.props.matcher(
          this.props.machine.value,
          this.props.matches,
        )}
      >
        {this.props.children}
      </RenderIf>
    );
  }
}

StateMatcher.propTypes = {
  matches: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  machine: PropTypes.shape({
    value: PropTypes.PropTypes.object.isRequired,
  }).isRequired,
  matcher: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
