import React from 'react';
import PropTypes from 'prop-types';
import StateMatcher from 'containers/StateMatcherContainer';
import LogoutButton from 'containers/LogoutButtonContainer';
import FaqModalLinkContainer from 'containers/FaqModalLinkContainer';
import PrivacyModalLinkContainer from 'containers/PrivacyModalLinkContainer';
import withTranslate from 'components/withTranslate';
import SmsModalLinkContainer from '../containers/SmsModalLinkContainer';
import ReleaseNotesModalLinkContainer from '../containers/ReleaseNotesModalLinkContainer';

const Spacer = () => <span style={{ padding: '.5em' }}>|</span>;

export const Footer = ({ _t }) => {
  return (
    <footer className="footer">
      <span>
        {_t('© {{date}} by i3logix, inc. All rights reserved.', {
          date: new Date().getFullYear(),
        })}
      </span>

      <Spacer />
      <FaqModalLinkContainer text={_t('Voter FAQ')} />
      <Spacer />
      <PrivacyModalLinkContainer text={_t('Your Privacy & Security')} />
      <Spacer />
      <SmsModalLinkContainer text={_t('SMS Terms')} />
      <StateMatcher matches={'app.loggedIn'}>
        <Spacer />
        <ReleaseNotesModalLinkContainer text={_t('Release Notes')} />
        <Spacer />
        <LogoutButton />
      </StateMatcher>
    </footer>
  );
};

export default withTranslate(Footer);

Footer.propTypes = {
  _t: PropTypes.func.isRequired,
};
