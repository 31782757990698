import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StateMatcher from '../../containers/StateMatcherContainer';
import BallotLogo from 'components/BallotLogo';
import Loading from '../../components/shared/Loading';
import ReactGA from 'react-ga4';

export const PrivacyPage = ({ _t }) => {
  useEffect(() => {
    ReactGA.set({ page: '/voter/privacy' });
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <StateMatcher matches={'translations.loading'}>
        <Loading className={'privacy-loading'} />
      </StateMatcher>

      <StateMatcher matches={'translations.complete'}>
        <div className="privacy-page policy-page">
          <nav className="nav-bar">
            <Link to="/">
              <BallotLogo />
            </Link>
          </nav>

          <main className="page-layout">
            <h1>{_t('Our commitment to your privacy')}</h1>
            <p>
              {_t(
                '{{ballotTrax}} fully respects its users right to privacy. We are just as concerned about your privacy and the use of your personal information. We assure you that any and all information you provide us with will remain confidential. {{ballotTrax}} is dedicated to protecting your privacy and ensuring confidence in our secure ballot locator and notification system.',
                { ballotTrax: _t('BallotTrax') },
              )}
            </p>
            <p>
              {_t(
                'By signing up with {{ballotTrax}}, you agree to opt-in to receive automatic mail ballot locating and notification services and you give your county permission to use your contact information to communicate election related information to you.',
                { ballotTrax: _t('BallotTrax') },
              )}
            </p>
            <p>
              {_t(
                'Your personal information will not be sold or in any way divulged to any 3rd parties. You may opt-out at any time.',
              )}
            </p>
          </main>

          <section>
            <Link to="/">
              <button className="primary-button privacy-button">
                <span>
                  {_t('Visit {{ballotTrax}}', { ballotTrax: _t('BallotTrax') })}
                </span>
              </button>
            </Link>
          </section>

          <footer>
            <span className="copy-right">
              {_t('© {{date}} by i3logix, inc. All rights reserved.', {
                date: new Date().getFullYear(),
              })}
            </span>
          </footer>
        </div>
      </StateMatcher>
    </>
  );
};

export default PrivacyPage;

PrivacyPage.propTypes = {
  _t: PropTypes.func.isRequired,
};
