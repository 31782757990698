import { QueryObserverResult, useQuery } from 'react-query';
import { standardHeaders } from './standardHeaders';
import handleJsonErrors from './handleJsonErrors';
import { FaqApiResponse } from '../interfaces/faqApiResponse';
import processJsonResponse from './processJsonResponse';

function useFaq(faqUrl: string): QueryObserverResult<FaqApiResponse, Error> {
  return useQuery(
    ['faqContent', faqUrl],
    () => {
      return fetch(faqUrl, {
        method: 'GET',
        headers: standardHeaders(),
      })
        .then(processJsonResponse)
        .then(handleJsonErrors);
    },
    {
      staleTime: 120 * 60 * 1000, // 2 hours
      cacheTime: 120 * 60 * 1000,
      retry: 1,
    },
  );
}

export default useFaq;
