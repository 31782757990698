import ReactGA from 'react-ga4';

const analyticsReducer = (state = {}, action) => {
  if (action.type === 'SEND_GA_EVENT') {
    ReactGA.event(action.eventData);
  }
  if (action.type === 'SEND_GA_PAGE_VISIT') {
    ReactGA.set({ page: action.page });
    ReactGA.send('pageview');
  }
  if (action.type === 'SEND_GA_MODAL_VIEW') {
    ReactGA.event({
      category: 'modal',
      action: action.page,
      label: 'Modal View',
      nonInteraction: false,
      transport: 'beacon',
      value: 0,
    });
  }
  return {};
};

export default analyticsReducer;
