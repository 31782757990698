import FAQ from '../../components/modal/content/FAQ';
import PrivacyDisclosure from '../../components/modal/content/PrivacyDisclosure';
import { AvailableModals } from '../../interfaces/modal';
import SmsPageContainer from '../../components/modal/content/SmsPolicy';
import ReleaseNotes from '../../components/modal/content/ReleaseNotes';

const availableModals: AvailableModals = {
  faq: {
    Component: FAQ,
    title: 'Frequently asked questions',
  },
  privacy: {
    Component: PrivacyDisclosure,
    title: 'Our commitment to your privacy',
  },
  smsPolicy: {
    Component: SmsPageContainer,
    title: 'SMS Terms',
  },
  releaseNotes: {
    Component: ReleaseNotes,
    title: 'Release Notes',
  },
};

export default availableModals;
