import { connect } from 'react-redux';
import { logout } from 'actionCreators';
import LogoutButton from 'components/buttons/LogoutButton';

const mapStateToProps = (state) => ({ machine: state.machine });

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => {
      return dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton);
