import React from 'react';
import withTranslate from 'components/withTranslate';
import LogoutButton from 'containers/LogoutButtonContainer';
import NavButton from 'components/buttons/NavButton';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleVoterProfile as toggleProfile } from 'actionCreators';
import { useFetchArchivedBallots } from '../apis/reactQueryHooks/useArchivedBallots';

// Add pushState event
// https://stackoverflow.com/questions/4570093/how-to-get-notified-about-changes-of-the-history-via-history-pushstate
var _wr = function (type) {
  var orig = window.history[type];
  return function () {
    var rv = orig.apply(this, arguments);
    var e = new Event(type);
    e.arguments = arguments;
    window.dispatchEvent(e);
    return rv;
  };
};

window.history.pushState = _wr('pushState');

export const MobileNav = ({ _t, toggleVoterProfile }) => {
  const { data: archivedBallots } = useFetchArchivedBallots(
    '/api/voter/archive-ballot?page_size=-1',
  );

  const goToMailStatus = (tabLabel) => {
    const tab = _t(tabLabel)[0];
    const mainContent = document.getElementById('dashboard-main-content');

    if (mainContent) {
      // Scroll to the tabbed Panel
      mainContent.scrollIntoView();
      window.history.pushState({ state: tab }, tab, '');
    }

    toggleVoterProfile();
  };

  return (
    <div className="voter-profile mobile">
      <NavButton
        label={_t('Current Mail Ballot Status')[0]}
        onClick={() => {
          goToMailStatus('Current Mail Ballot Status');
        }}
      />
      {archivedBallots?._embedded.ballot.length > 0 ? (
        <NavButton
          label={_t('Previous Mail Ballots')[0]}
          onClick={() => {
            goToMailStatus('Previous Mail Ballots');
          }}
        />
      ) : null}

      <LogoutButton />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleVoterProfile: () => {
    return dispatch(toggleProfile());
  },
});

export default connect(mapDispatchToProps)(withTranslate(MobileNav));

MobileNav.propTypes = {
  _t: PropTypes.func.isRequired,
  toggleVoterProfile: PropTypes.func.isRequired,
};
