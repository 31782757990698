import React from 'react';
import PropTypes from 'prop-types';
import PreferencesForm from 'containers/preferences/PreferencesFormContainer';
import withTranslate from 'components/withTranslate';

export const Preferences = ({
  _t,
  rootClassName = 'page-layout',
  shouldRedirect = true,
  onDashboard = false,
}) => (
  <div
    className={`preferences ${rootClassName} preferences-page`}
    id={'contact-preferences'}
    data-testid={'preferences'}
  >
    <div className="dashboard-title">{_t('Contact Preferences')}</div>
    <PreferencesForm
      shouldRedirect={shouldRedirect}
      onDashboard={onDashboard}
    />
  </div>
);

export default withTranslate(Preferences);

Preferences.propTypes = {
  _t: PropTypes.func.isRequired,
  rootClassName: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  onDashboard: PropTypes.bool,
};
