import React from 'react';
import { User } from '../../interfaces/user';
import PreferenceErrors from '../../interfaces/PreferenceErrors';

interface ErrorRenderProps {
  render: (errors: {}) => JSX.Element;
  errors: PreferenceErrors;
  user: User;
  verifyUserData: (user: User) => PreferenceErrors;
  touched: { [field: string]: boolean };
}

// This component gets errors from Formik validation, runs checks for
// other errors against the redux state, and then passes down
// all those errors in a single object to the preference form
const PreferenceErrorGatherer = ({
  render,
  errors,
  user,
  touched,
  verifyUserData,
}: ErrorRenderProps) => {
  let apiErrors = verifyUserData(user);
  // TODO: move 'touched' logic for Formik-generated errors in here to simplify components
  // Filter out 'touched' fields - the front-end has special logic for those
  apiErrors = Object.keys(apiErrors).reduce(
    (untouchedErrors, field) =>
      touched[field]
        ? untouchedErrors
        : Object.assign({}, untouchedErrors, { [field]: apiErrors[field] }),
    {},
  );
  const consolidatedErrors = consolidateErrors(apiErrors, errors);

  return <>{render(consolidatedErrors)}</>;
};

function consolidateErrors(
  errors1: PreferenceErrors,
  errors2: PreferenceErrors,
): PreferenceErrors {
  const consolidatedErrors = Object.assign({}, errors1);

  for (const key in errors2) {
    if (consolidatedErrors[key] !== undefined) {
      consolidatedErrors[key] = [...consolidatedErrors[key], ...errors2[key]];
    } else {
      consolidatedErrors[key] = errors2[key];
    }
  }
  return consolidatedErrors;
}

export default PreferenceErrorGatherer;
