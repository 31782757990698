import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import StateMatcher from '../../containers/StateMatcherContainer';
import BallotLogo from 'components/BallotLogo';
import Loading from '../../components/shared/Loading';
import ReactGA from 'react-ga4';
import useFaq from '../../apis/useFaq';

const FAQPage = ({ _t, currentLanguage }) => {
  useEffect(() => {
    ReactGA.set({ page: '/voter/faq' });
    ReactGA.send('pageview');
  }, []);

  const { isLoading, error, data } = useFaq(
    `/api/voter/faq?language=${currentLanguage}`,
  );

  const getContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return (
        <div className={'error faq-error'} role="region" aria-live="polite">
          {error.message}
        </div>
      );
    }

    if (data.content === undefined) {
      return null;
    }

    return (
      <dl
        className="faq-page-dl"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    );
  };

  return (
    <>
      <StateMatcher matches={'translations.loading'}>
        <Loading className={'faq-loading'} />
      </StateMatcher>

      <StateMatcher matches={'translations.complete'}>
        <div className="faq-page policy-page">
          <nav className="nav-bar">
            <Link to="/">
              <BallotLogo />
            </Link>
          </nav>

          <main className="page-layout">
            <h1>{_t('Frequently asked questions')}</h1>
            {getContent()}
          </main>

          <section>
            <Link to="/">
              <button className="primary-button">
                <span>
                  {_t('Visit {{ballotTrax}}', { ballotTrax: _t('BallotTrax') })}
                </span>
              </button>
            </Link>
          </section>

          <footer>
            <span className="copy-right">
              {_t('© {{date}} by i3logix, inc. All rights reserved.', {
                date: new Date().getFullYear(),
              })}
            </span>
          </footer>
        </div>
      </StateMatcher>
    </>
  );
};

export default FAQPage;

FAQPage.propTypes = {
  _t: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};
