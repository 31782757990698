import React from 'react';
import withTranslate from 'components/withTranslate';
import { useFetchArchivedBallots } from 'apis/reactQueryHooks/useArchivedBallots';
import Loading from 'components/shared/Loading';
import ArchivedBallotsList from './ArchivedBallotsList';

export interface ArchivedBallotsProps {
  _t: any;
  showTitle: boolean;
}

const ArchivedBallots = ({ _t, showTitle = true }: ArchivedBallotsProps) => {
  const { data: archivedBallots, isLoading } = useFetchArchivedBallots(
    '/api/voter/archive-ballot?page_size=-1',
  );

  if (isLoading) {
    return <Loading />;
  }

  // Don't render this component when there are no past ballots
  if (!archivedBallots || archivedBallots._embedded.ballot.length === 0) {
    return null;
  }

  return (
    <div className="past-ballots">
      {showTitle ? (
        <span className="dashboard-title">{_t('Previous Mail Ballots')}</span>
      ) : null}
      <ArchivedBallotsList ballots={archivedBallots._embedded.ballot} />
    </div>
  );
};

export default withTranslate(ArchivedBallots);
