import React from 'react';
import { SvgProps } from '../interfaces/svg';

const ArrowDropdown = ({
  width = '14',
  height = '14',
  color = 'white',
  className = 'none',
  title,
}: SvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="7 10 10 5"
    enableBackground="new 0 0 24 24"
    className={className}
  >
    {title ? <title>{title}</title> : null}
    <path fill={color} d="M7 10l5 5 5-5z" />
  </svg>
);

export default ArrowDropdown;
