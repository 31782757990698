/* tslint:disable:max-classes-per-file */
export class GenericApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'GenericApiError';
  }
}

export class NotFound extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NotFound';
  }
}

export class BadRequest extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BadRequest';
  }
}
