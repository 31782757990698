import { addMessages } from '../alerts/preferenceForm/preferencesFormAlertBuilder';

export const loginErrorMessage = (phrase: string, errId?: string) => {
  let errCode = '';
  if (errId) {
    errCode = ` ${errId}`;
  }

  let action = {
    type: 'UPDATE_GLOBAL_ALERTS',
    alerts: {},
  };
  const defaultMessage = [
    {
      important: false,
      text: `Oops! Something went wrong on our end. Please try your latest action again.${errCode}`,
    },
  ];
  const invalid = [
    {
      important: true,
      text: 'Invalid credentials',
    },
    {
      important: false,
      text: "Your information doesn't match any voters in our system. Please double-check that what you've entered is correct and make sure that your first name is the same as your first name on your voter registration.",
    },
  ];
  const ambiguous = [
    {
      important: true,
      text: 'Ambiguous Credentials',
    },
    {
      important: false,
      text: 'Your information matches someone else from our records. Please enter your voter ID to verify your account.',
    },
  ];

  const recaptcha = [
    {
      important: true,
      text: 'Invalid ReCAPTCHA',
    },
    {
      important: false,
      text: 'Please refresh the page and try again.',
    },
  ];

  const statusMessageMap = {
    'Invalid credentials': addMessages('error', invalid, action),
    'Failed Validation': addMessages('error', invalid, action),
    'Ambiguous Credentials': addMessages('notice', ambiguous, action),
    'Invalid ReCAPTCHA': addMessages('error', recaptcha, action),
  };

  action =
    statusMessageMap[phrase] || addMessages('error', defaultMessage, action);
  return action;
};

export default loginErrorMessage;
