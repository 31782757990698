import React from 'react';
import ArrowLeft from '../icons/ArrowLeft';
import ArrowRight from '../icons/ArrowRight';
import { PaginationLinks } from '../../interfaces/Pagination';

interface PaginationProps {
  paginationLinks: PaginationLinks;
  setPaginationUrl: (url: string) => void;
  className: string;
}

const Pagination = ({
  paginationLinks,
  setPaginationUrl,
  className = '',
}: PaginationProps) => {
  const firstPage = paginationLinks.page === 1;
  const lastPage = paginationLinks.page === paginationLinks.pageCount;

  return (
    <nav
      aria-label={'Release Notes Pagination'}
      className={`pagination ${className}`}
    >
      <ul className={'pagination-list'}>
        <div className={'button-container'}>
          <li key={'first'}>
            <button
              type="button"
              aria-label={'Page 1'}
              onClick={() =>
                paginationLinks.first && setPaginationUrl(paginationLinks.first)
              }
              className={`${
                firstPage ? 'disabled' : 'to-first-page'
              } first-button`}
              disabled={firstPage}
            >
              First
            </button>
          </li>

          <li key={'previous'}>
            <button
              type="button"
              aria-label={'Previous Page'}
              className={`${
                firstPage ? 'disabled' : 'to-previous-page'
              } prev-button`}
              onClick={() =>
                paginationLinks.prev && setPaginationUrl(paginationLinks.prev)
              }
              disabled={firstPage}
            >
              <ArrowLeft color={firstPage ? '#75797E' : '#000000'} />
              Prev
            </button>
          </li>
        </div>

        <li key={'current'}>
          <span className={'screen-reader'}>
            Page{' '}
            {`${paginationLinks.page} of ${paginationLinks.pageCount}, current page`}
          </span>
          <span aria-hidden={true} className="page-count">
            {paginationLinks.page} / {paginationLinks.pageCount}
          </span>
        </li>

        <div className={'button-container'}>
          <li key={'next'}>
            <button
              type="button"
              aria-label={'Next Page'}
              className={`${
                lastPage ? 'disabled' : 'to-previous-page'
              } next-button`}
              onClick={() =>
                paginationLinks.next && setPaginationUrl(paginationLinks.next)
              }
              disabled={lastPage}
            >
              Next
              <ArrowRight color={lastPage ? '#75797E' : '#000000'} />
            </button>
          </li>

          <li key={'last'}>
            <button
              type="button"
              aria-label={`Page ${paginationLinks.pageCount}`}
              className={`${
                lastPage ? 'disabled' : 'to-last-page'
              } last-button`}
              onClick={() =>
                paginationLinks.last && setPaginationUrl(paginationLinks.last)
              }
              disabled={lastPage}
            >
              Last
            </button>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default Pagination;
