import { QueryObserverResult, useQuery } from 'react-query';
import replaceUrlDomainWithBrowserDomain from '../../util/replaceUrlDomainWithBrowserDomain';
import { standardHeaders } from '../standardHeaders';
import processJsonResponse from '../processJsonResponse';
import handleJsonErrors from '../handleJsonErrors';
import { ArchivedBallotsApiResponse } from '../../interfaces/archivedBallots';
import cookies from '../../util/cookies/cookies';

export const useFetchArchivedBallots = (
  url: string,
  options?: any,
): QueryObserverResult<ArchivedBallotsApiResponse, Error> => {
  let queryOptions = {
    staleTime: 0,
  };
  if (options) {
    queryOptions = { ...queryOptions, ...options };
  }

  const language = cookies.get('language') || 'english';

  return useQuery(
    [url + `&language=${language}`],
    () => {
      return fetch(
        replaceUrlDomainWithBrowserDomain(url + `&language=${language}`),
        {
          method: 'GET',
          headers: standardHeaders(),
        },
      )
        .then(processJsonResponse)
        .then(handleJsonErrors);
    },
    queryOptions,
  );
};
