import React from 'react';
import StartTimeInputField from 'components/inputs/StartTimeInputField';
import generateTimeList from 'util/time/generateTimeList';
import * as PropTypes from 'prop-types';

const StartTimeInputFieldContainer = (props) => {
  return (
    <StartTimeInputField
      times={generateTimeList(props.exclude, props.include)}
      {...props}
    />
  );
};

export default StartTimeInputFieldContainer;

StartTimeInputFieldContainer.propTypes = {
  exclude: PropTypes.string,
  include: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
};
