import { connect } from 'react-redux';
import { openModal, sendAnalyticsModalView } from 'actionCreators';
import ModalLink from 'components/shared/ModalLink';

const mapDispatchToProps = (dispatch) => ({
  clickHandler: () => {
    dispatch(sendAnalyticsModalView('sms-policy'));
    return dispatch(openModal('smsPolicy'));
  },
});

export default connect(null, mapDispatchToProps)(ModalLink);
