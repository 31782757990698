import React from 'react';
import * as PropTypes from 'prop-types';
import FieldNote from 'components/notifications/FieldNote';
import { messages } from 'components/notifications/preferenceFormMessages';

export const PreferencesFieldNoteContainer = ({
  field,
  optStatus,
  replacements,
}) => {
  return messages[field][optStatus ? 'optIn' : 'optOut'].map((message, i) => {
    return <FieldNote key={i} message={message} replacements={replacements} />;
  });
};

export default PreferencesFieldNoteContainer;

PreferencesFieldNoteContainer.propTypes = {
  field: PropTypes.string.isRequired,
  optStatus: PropTypes.bool,
  replacements: PropTypes.object,
};
