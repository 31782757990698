import React from 'react';
import ErrorMessageRenderer from './ErrorMessageRenderer';

interface ErrorMessageListProps {
  field: string;
  errors?: string[] | string;
  limit?: number;
  id?: string;
}

const ErrorMessageList = ({
  field,
  errors,
  limit,
  id,
}: ErrorMessageListProps) => {
  // let errorList = errors || [];
  let errorList: string[] = [];
  if (typeof errors === 'string') {
    errorList.push(errors);
  } else {
    if (errors) {
      errorList = errors;
    }
  }

  errorList = limit ? errorList.slice(0, limit) : errorList;
  return (
    <>
      {errorList.map((error) => (
        <ErrorMessageRenderer
          key={error} // @ts-ignore
          error={error}
          field={field}
          id={id}
        />
      ))}
    </>
  );
};

export default ErrorMessageList;
