import { connect } from 'react-redux';
import { closeModal } from '../actionCreators';
import Modal from 'components/modal/Modal';
import availableModals from 'util/modal/availableModals';

const mapStateToProps = ({ modal }) => ({
  isOpen: !!modal.currentlyOpenModal,
  ...availableModals[modal.currentlyOpenModal],
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
