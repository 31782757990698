import React from 'react';
import PropTypes from 'prop-types';
import CurrentBallots from 'components/currentBallots/CurrentBallot';
import RenderIf from 'components/shared/RenderIf';
import withTranslate from 'components/withTranslate';
import NoActiveBallots from 'containers/NoActiveBallotsContainer';

export const ElectionOverview = ({
  hasCurrentBallot,
  currentBallots,
  _t,
  showTitle = true,
}) => (
  <div className="election-overview">
    {showTitle ? (
      <span className="dashboard-title">
        {_t('Current Mail Ballot Status')}
      </span>
    ) : null}
    <RenderIf condition={hasCurrentBallot}>
      <CurrentBallots currentBallots={currentBallots} />
    </RenderIf>

    <RenderIf condition={!hasCurrentBallot}>
      <NoActiveBallots showTitle={showTitle} />
    </RenderIf>
  </div>
);

export default withTranslate(ElectionOverview);

ElectionOverview.propTypes = {
  hasCurrentBallot: PropTypes.bool.isRequired,
  currentBallots: PropTypes.array.isRequired,
  _t: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
};
