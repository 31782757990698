import { connect } from 'react-redux';
import App from 'App';
import { actionMap } from '../actionMap';
import { setLDFlags } from '../actionCreators';

const mapDispatchToProps = (dispatch) => ({
  setLanguageFromQueryParam: () => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('language');

    if (!language) {
      return;
    }
    actionMap.setCurrentLanguage(dispatch, language.toLowerCase());
  },
  setLDFlags: (flags) => {
    dispatch(setLDFlags(flags));
  },
});

export default connect(null, mapDispatchToProps)(App);
