import { connect } from 'react-redux';
import LanguageDropdown from 'components/LanguageDropdown';
import { actionMap } from 'actionMap';
import { abbreviations } from 'data/supported-languages';

const mapStateToProps = ({ language, machine }) => ({
  availableLanguages: language.availableLanguages,
  currentLanguage: language.currentLanguage,
  abbreviations,
  isLoggedIn: machine.value.app === 'loggedIn',
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentLanguage: (language, isLoggedIn) => {
      return actionMap.setCurrentLanguage(dispatch, language, isLoggedIn);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);
