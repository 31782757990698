import { connect } from 'react-redux';
import { matchesState } from 'xstate';
import MatchRenderer from 'components/stateMachine/StateMatcher';

const matcher = (machineState, matches) => {
  const values = Array.isArray(matches) ? matches : [matches];
  return values.some((val) => matchesState(val, machineState));
};

const mapStateToProps = (state) => ({
  matcher: matcher,
  machine: state.machine,
});

export default connect(mapStateToProps, null)(MatchRenderer);
