import React from 'react';
import { Translations } from '../interfaces/translations';
import getTranslator from '../util/translator/translator';

const initialTranslations: Readonly<Translations> = {
  arabic: 'العربية',
  bengali: 'বাঙালি',
  cantonese: '廣東話',
  chinese: '中文',
  creole: 'Kreyòl',
  english: 'English',
  french: 'française',
  german: 'Deutsch',
  hawaiian: 'ʻŌlelo Hawaiʻi',
  hindi: 'हिंदी',
  ilocano: 'Ilocano',
  japanese: '日本語',
  khmer: 'ខ្មែរ។',
  korean: '한국어',
  russian: 'Столбец1',
  somali: 'soomaaliga',
  spanish: 'Español',
  tagalog: 'Tagalog',
  thai: 'ไทย',
  ukrainian: 'українська',
  vietnamese: 'Tiếng Việt',
  BALLOTTRAX_FAQ_LINK:
    '\u003Ca href=\u0022https://ballottrax.net/voter/faq\u0022\u003Ehttps://ballottrax.net/voter/faq\u003C/a\u003E',
  NEW_VOTER_LOGIN_INTRO: '',
  CUSTOM_LOGO_URL: '-NONE-',
};

export interface Translator {
  _t: (
    phrase: string,
    replacements?: {},
  ) => Array<string | React.ReactElement<any>>;
}

const initialTranslator = {
  _t: getTranslator(initialTranslations, false),
};

export default initialTranslator;
