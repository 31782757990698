import React from 'react';
import PropTypes from 'prop-types';

const style = {
  fontFamily: 'Lato',
  fontSize: '0.4em',
  transform: 'translateY(0.2em)',
  textAnchor: 'middle',
  color: '#03314c',
};

const calcCompletedSegment = (currentStep, totalSteps) => {
  const stepPercentage = 100 / totalSteps;
  const filled = stepPercentage * currentStep;
  const unfilled = 100 - filled;

  return `${filled} ${unfilled}`;
};

const ProgressGraphic = ({
  size = 190,
  currentStep,
  totalSteps,
  displayText,
  isComplete,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 42 42"
    className="progress-graphic"
  >
    <circle
      className="graphic-hole"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="#f0f0f0"
    />

    <circle
      className="graphic-uncompleted-ring"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#d2d3d4"
      strokeWidth="2.5"
    />

    <circle
      className="graphic-completed-segment"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#8CC63E"
      strokeWidth="2.5"
      strokeDasharray={calcCompletedSegment(currentStep, totalSteps)}
      strokeDashoffset="-25"
      strokeLinecap="round"
    />

    <g style={style}>
      <text x="50%" y="50%" fontSize={isComplete ? '4' : '5'} fill={'#03314C'}>
        {displayText}
      </text>
    </g>
  </svg>
);

export default ProgressGraphic;

ProgressGraphic.propTypes = {
  size: PropTypes.number,
  displayText: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  isComplete: PropTypes.bool,
};
