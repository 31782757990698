import moment from 'moment/moment';

const generateTimeList = (exclude, include) => {
  const excludeTime = moment(exclude, 'HH:mm');
  const includeTime = moment(include, 'HH:mm');

  const timeOfDay = moment().startOf('day');
  const endOfDay = moment().endOf('day');

  const list = [];
  if (includeTime.isValid()) {
    list.push(includeTime);
  }

  while (timeOfDay.isBefore(endOfDay)) {
    // make a new obj out of it, because moment is mutable
    list.push(moment(timeOfDay));
    timeOfDay.add(30, 'minutes');
  }

  let previousTime = moment(null);
  return list
    .sort((a, b) => (a.isBefore(b) ? -1 : 1))
    .filter((time) => {
      // de-dupe the list, get rid of excluded time
      if (time.isSame(previousTime) || time.isSame(excludeTime)) {
        return false;
      }
      previousTime = moment(time);
      return true;
    });
};

export default generateTimeList;
