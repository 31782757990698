import { Action } from '../interfaces/action';

const launchDarklyReducer = (
  state = {
    clientId: null,
    flags: {},
  },
  action: Action,
): Readonly<{
  clientId: string | null;
  flags: { [index: string]: boolean };
}> => {
  if (action.type === 'SET_LAUNCHDARKLY_CLIENT_ID') {
    const { clientId } = action as { type: string; clientId: string };
    return {
      ...state,
      clientId,
    };
  }

  if (action.type === 'SET_LD_FLAGS') {
    const { flags } = action as {
      type: string;
      flags: { [index: string]: boolean };
    };

    return {
      ...state,
      flags,
    };
  }

  return state;
};

export default launchDarklyReducer;
