import React from 'react';
import PropTypes from 'prop-types';
import StateMatcher from 'containers/StateMatcherContainer';
import Loading from 'components/shared/Loading';
import withTranslate from 'components/withTranslate';
import { Field, ErrorMessage } from 'formik';
import ErrorMessageRenderer from '../notifications/ErrorMessageRenderer';

const validate = (value) => {
  if (value === '') {
    return 'Voter ID is required.';
  }
};

export const AmbiguousForm = (props) => {
  const { _t } = props;

  return (
    <div className="ambiguous-container" data-testid={'ambiguous-form'}>
      <div className="ambiguous-title-section">
        {/*"Ambiguous Credentials" doesn't exist in translations*/}
        <p
          className={'header'}
          dangerouslySetInnerHTML={{
            __html: _t('Ambiguous Credentials').join(),
          }}
        />
        <p
          className={'subheader'}
          dangerouslySetInnerHTML={{
            __html: _t(
              'Your information matches someone else from our records. Please enter your voter ID to verify your account.',
            ).join(),
          }}
        />
      </div>
      <StateMatcher matches={'loginForm.ambiguousSubmitting'}>
        <div className="ambiguous-loading">
          <Loading />
        </div>
      </StateMatcher>
      <StateMatcher
        matches={['loginForm.ambiguous', 'loginForm.ambiguousError']}
      >
        <section>
          <label htmlFor={'voter-id'}>{_t('VOTER ID')}</label>
          <Field
            autoFocus
            id={'voter-id'}
            name={'provided_voter_id'}
            placeholder={_t('County Issued Voter ID')}
            onKeyDown={(e) => props.escHandler(e)}
            validate={validate}
            aria-describedby={'voter-id-error'}
          />
          <ErrorMessage
            name={'provided_voter_id'}
            render={(error) => (
              <ErrorMessageRenderer
                error={error}
                field={'provided_voter_id'}
                id={'voter-id-error'}
              />
            )}
          />
        </section>
      </StateMatcher>
    </div>
  );
};

export default withTranslate(AmbiguousForm);

AmbiguousForm.propTypes = {
  _t: PropTypes.func.isRequired,
  escHandler: PropTypes.func.isRequired,
  fields: PropTypes.object,
};
