import React from 'react';
import { Field } from 'formik';
import PreferencesTitleSection from '../icons/PreferencesTitleSection';
import OptSwitch from '../OptSwitch';
import ErrorMessageRenderer from '../notifications/ErrorMessageRenderer';
import FieldNote from '../../containers/preferences/PreferencesFieldNoteContainer';
import withTranslate from '../withTranslate';
import ErrorMessageList from '../notifications/ErrorMessageList';
import { EmailStatus } from '../../interfaces/EmailStatus';
import { preferenceErrorMessages } from '../notifications/preferenceErrorMessages';

export interface EmailFieldProps {
  values: {
    emailOptIn: boolean;
    isValidEmail: boolean | null;
    email?: string;
  };
  setValues: (o: object) => void;
  _t: (str: string) => Array<string | JSX.Element>;
  user: {
    email: string;
  };
  errors: {
    email?: string[];
  };
  touched: {
    email: any;
  };
  machine: any;
}

export const EmailField = ({
  values,
  setValues,
  _t,
  user,
  errors,
  touched,
}: EmailFieldProps) => {
  const { UNKNOWN, VALID, INVALID } = EmailStatus;
  let emailStatus: EmailStatus;

  if (values.isValidEmail === null) {
    emailStatus = UNKNOWN;
  } else {
    emailStatus = values.isValidEmail ? VALID : INVALID;
  }

  // @ts-ignore
  return (
    <section className="preference-section email-section">
      <div className="preference-section-header">
        <PreferencesTitleSection
          type={'email'}
          title={_t('Opt-In Email') as string[]}
        />
        <OptSwitch
          _t={_t}
          id={'Email'}
          optedIn={values.emailOptIn}
          onClick={() => {
            setValues({
              ...values,
              emailOptIn: !values.emailOptIn,
            });
          }}
        />
      </div>

      <div className="input-section">
        <label
          htmlFor={'email'}
          className="preference-label email-preference-label"
        >
          {_t('Email')}
        </label>
        <div>
          <Field
            className="preference-input"
            id={'email'}
            name={'email'}
            placeholder={_t('Example: test@sample.com')}
            onChange={(e: any) => {
              setValues({
                ...values,
                email: e.target.value,
                emailOptIn: true,
                isValidEmail:
                  user.email === '' || user.email !== e.target.value,
              });
            }}
            aria-describedby={'email-error-list email-error'}
          />
          <button
            className="clear-field"
            type="button"
            onClick={() =>
              setValues({
                ...values,
                email: '',
                emailOptIn: false,
                isValidEmail: true,
              })
            }
          >
            &times;
            <div className="screen-reader">
              {_t('Clear email field contents')}
            </div>
          </button>
        </div>
      </div>
      <div
        className={''}
        aria-live="polite"
        aria-atomic="true"
        aria-relevant="all"
      >
        {touched.email && (
          <ErrorMessageList
            errors={errors.email}
            field={'email'}
            limit={3}
            id={'email-error-list'}
          />
        )}
        {emailStatus === INVALID && !errors.email && (
          <ErrorMessageRenderer
            // @ts-ignore
            error={preferenceErrorMessages.email.invalidEmail}
            field={'email'}
            invalidEmail
            id={'email-error'}
          />
        )}
        {!(errors.email && touched.email) && emailStatus !== INVALID && (
          <FieldNote
            field={'email'}
            optStatus={values.emailOptIn}
            replacements={{}}
          />
        )}
      </div>
    </section>
  );
};

export default withTranslate(EmailField);
