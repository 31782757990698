import { connect } from 'react-redux';
import { revert, submit } from 'actionCreators';
import LoginForm from 'components/forms/LoginForm';

const mapStateToProps = (state) => ({
  machine: state.machine,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubmit: (user) => {
      return dispatch(submit(user));
    },
    revert: () => {
      // back out of ambiguous or token state
      return dispatch(revert());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
