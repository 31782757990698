import React from 'react';
import PropTypes from 'prop-types';

// *svg-cleanup*
const icons = {
  completed: (
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      fill="#8cc63e"
    />
  ),
  incomplete: (
    <path
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      fill="#f0f0f0"
    />
  ),
  halted: (
    <path
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      fill="#ff0000"
    />
  ),
  caution: (
    <path
      d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      fill="#ffca2b"
    />
  ),
};

const BallotInfoIcon = ({ iconType, size = 30 }) => (
  <div
    style={{
      zIndex: '2',
    }}
    className="ballot-info-icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
      aria-label={iconType}
    >
      <path d="M0 0h24v24H0z" fill="#ffffff" />
      {icons[iconType]}
    </svg>
  </div>
);

BallotInfoIcon.propTypes = {
  iconType: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default BallotInfoIcon;
