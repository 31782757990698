import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../shared/Loading';
import Unsubscribe from '../Unsubscribe';

interface UnsubscribePageProps {
  unsubscribe: (
    key: string,
    email: string,
  ) => Promise<UnsubscribeApiResponse | Error>;
}

interface UnsubscribeParams {
  email: string;
  key: string;
}

interface UnsubscribeApiResponse {
  voters_removed: number;
  messages_removed: number;
}

const genericErrorMessage =
  'There was an error removing your email. Please click the link in your email to unsubscribe.';

const UnsubscribePage = ({ unsubscribe }: UnsubscribePageProps) => {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isUnsubscribed, setIsUnsubscribed] = React.useState<boolean>(false);

  let params: Partial<UnsubscribeParams> = {};

  // split the query string into params.email & params.key
  window.location.search
    .substring(1)
    .split('&')
    .forEach((param) => {
      const keyValue = param.split('=');
      params[keyValue[0]] = keyValue[1];
    });

  // if an email was provided in the query, decode it to be human readable
  if (params.email) {
    params.email = decodeURIComponent(params.email);
  }

  useEffect(() => {
    // if both params were not provided by the query string, stop loading and notify the user of an error
    if (!params.key || !params.email) {
      setErrorMessage(genericErrorMessage);
    }
  }, [params.email, params.key]);

  const handleClickUnsubscribe = () => {
    if (!params.key || !params.email) {
      return;
    }
    setLoading(true);
    unsubscribe(params.key, params.email)
      .then(() => {
        setLoading(false);
        setIsUnsubscribed(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.name === 'BadRequest') {
          setErrorMessage(err.message);
        } else {
          setErrorMessage(genericErrorMessage);
        }
      });
  };

  return (
    <div className={'unsubscribe-page'}>
      <div className={'unsubscribe-content'}>
        {loading ? (
          <Loading />
        ) : isUnsubscribed || errorMessage ? (
          <Unsubscribe
            unsubscribed={isUnsubscribed}
            email={params.email}
            error={errorMessage}
          />
        ) : (
          <>
            <p>
              We're sorry to see you go! By clicking the Unsubscribe button
              below, you will no longer receive emails from BallotTrax.
            </p>
            <p>
              If you do not want to unsubscribe, click Visit BallotTrax to sign
              in and view your ballot status.
            </p>
            <div className={'buttons-container'}>
              <button
                type={'button'}
                className={'primary-button unsubscribe-button'}
                onClick={handleClickUnsubscribe}
              >
                Unsubscribe
              </button>
              <Link to="/">
                <button className="primary-button visit-button">
                  <span>Visit BallotTrax</span>
                </button>
              </Link>{' '}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UnsubscribePage;
