import React from 'react';
import EndTimeInputField from 'components/inputs/EndTimeInputField';
import generateTimeList from 'util/time/generateTimeList';
import * as PropTypes from 'prop-types';

const EndTimeInputFieldContainer = (props) => {
  return (
    <EndTimeInputField
      times={generateTimeList(props.exclude, props.include)}
      {...props}
    />
  );
};

export default EndTimeInputFieldContainer;

EndTimeInputFieldContainer.propTypes = {
  exclude: PropTypes.string,
  include: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
};
