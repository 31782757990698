import React from 'react';
import PropTypes from 'prop-types';
import LogoCounty from '../containers/LogoCountyContainer';
import NavBarButtons from 'components/NavBarButtons';

const NavBar = ({ availableLanguages }) => (
  <nav className="nav-bar">
    <LogoCounty />
    <NavBarButtons multipleLanguages={availableLanguages.length > 1} />
  </nav>
);

export default NavBar;

NavBar.propTypes = {
  availableLanguages: PropTypes.array.isRequired,
};
